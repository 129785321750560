<template>
  <div ref="root" class="tw-flex tw-w-full tw-flex-1 tw-flex-col tw-gap-5 tw-py-5 xl:tw-flex-row xl:tw-items-stretch">
    <div class="tw-flex tw-flex-col tw-items-end tw-space-y-1">
      <Preview ref="preview" :style="previewStyle" />
      <ResourcesUsage v-if="resourcesUsage" class="tw-justify-end tw-text-right sm:tw-mr-13" />
    </div>

    <ControlModes ref="controlModes" class="tw-w-full xl:tw-w-auto xl:tw-min-w-max" />
  </div>
</template>

<script setup>
import Preview from '@/components/Preview.vue';
import ControlModes from '@/components/controlModes/ControlModes.vue';
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import { useWindowSize } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { nextTick, onMounted, ref, useTemplateRef, watch } from 'vue';

import ResourcesUsage from './ResourcesUsage.vue';

const appStore = useAppStore();
const { resourcesUsage } = storeToRefs(appStore);
const dataStore = useDataStore();
const { controller } = storeToRefs(dataStore);

const rootRef = useTemplateRef('root');
const previewRef = useTemplateRef('preview');
const controlModesRef = useTemplateRef('controlModes');

const { height: windowHeight, width: windowWidth } = useWindowSize();

const previewStyle = ref(null);

const recalculatePreviewStyle = () => {
  if (!rootRef.value || !controlModesRef.value) return;

  const zoomSliderWidth = windowWidth.value < 640 ? 0 : 32;
  const previewSliderGap = zoomSliderWidth ? 20 : 0;
  const previewControlModesGap = 20;

  previewStyle.value = null;
  controlModesRef.value.el.style.cssText = '';

  if (windowWidth.value < 1280) {
    const width = rootRef.value.clientWidth;
    const height = ((width - zoomSliderWidth - previewSliderGap) / 16) * 9;

    previewStyle.value = {
      width: `${width}px`,
      height: `${height}px`,
    };

    return;
  }

  const previewStyleResult = {};

  const availableWidth = rootRef.value.clientWidth - controlModesRef.value.el.clientWidth - previewControlModesGap;
  const availableHeight =
    rootRef.value.firstElementChild.clientHeight -
    (resourcesUsage.value ? previewRef.value.el.nextElementSibling.clientHeight : 0);

  let width = (rootRef.value.clientHeight / 9) * 16 + zoomSliderWidth + previewSliderGap;

  if (width > availableWidth) {
    width = availableWidth;
  }

  let height = ((width - zoomSliderWidth - previewSliderGap) / 16) * 9;

  if (height > availableHeight) {
    height = availableHeight;
    width = (height / 9) * 16 + zoomSliderWidth + previewSliderGap;
  }

  previewStyleResult.width = `${width}px`;
  previewStyleResult.height = `${height}px`;

  previewStyle.value = previewStyleResult;
  controlModesRef.value.el.style.cssText = 'flex: 1;';
};

watch([windowHeight, windowWidth], recalculatePreviewStyle);

watch([() => controller.value.control_mode, resourcesUsage], async () => {
  await nextTick();

  recalculatePreviewStyle();
});

onMounted(recalculatePreviewStyle);
</script>
