<template>
  <Notice
    :tag="tag"
    :type="notification.type"
    :title="notification.title"
    :message="notification.message"
    :closable="notification.closable"
    :scroll-to-on-init="false"
    :message-attrs="{ class: 'tw-whitespace-pre-line' }"
    class="tw-pointer-events-auto tw-relative"
    @close="emit('close', notification.id)"
  >
    <MyDetails v-if="detail" :summary-attrs="{ custom: true }">
      <template #summary>{{ $t('show_details') }}</template>

      <template #body>
        <div class="fuse-fuse-prose">
          <pre class="max-w-full tw-overflow-x-auto">{{ detail }}</pre>
        </div>
      </template>
    </MyDetails>
  </Notice>
</template>

<script setup>
import { MyDetails, Notice } from '@slideslive/fuse-kit/vue';
import { computed } from 'vue';

const props = defineProps({
  tag: {
    type: String,
    default: 'li',
  },
  notification: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['close']);

const detail = computed(() => {
  if (props.notification.detail) {
    return JSON.stringify(props.notification.detail, null, 2);
  }

  return null;
});
</script>
