<template>
  <div
    ref="root"
    :class="['tw-absolute tw-inset-0 tw-z-10 tw-cursor-move', !locked && !previewActionDisabled && 'tw-touch-none']"
    @click="setPosition"
  >
    <svg v-if="pressed" class="tw-size-full">
      <path :d="arrowPath" stroke="none" class="tw-fill-primary" />

      <g>
        <circle stroke="none" :cx="startPosition.x" :cy="startPosition.y" :r="dotRadius" class="tw-fill-red" />

        <circle
          fill="none"
          :cx="startPosition.x"
          :cy="startPosition.y"
          :r="ringRadius"
          class="tw-stroke-white tw-stroke-2"
        />
      </g>
    </svg>
  </div>
</template>

<script setup>
import api from '@/api';
import useLoadingRequest from '@/composables/loading_request';
import usePointermove from '@/composables/pointermove';
import { MOVE_HEIGHT_THRESHOLD, MOVE_WIDTH_THRESHOLD } from '@/constants';
import useAppStore from '@/stores/app';
import { useElementSize, useMagicKeys } from '@vueuse/core';
import { throttle } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, reactive, ref, useTemplateRef, watch, watchEffect } from 'vue';

const appStore = useAppStore();
const { locked, previewActionDisabled } = storeToRefs(appStore);

const rootRef = useTemplateRef('root');

const dotRadius = 8;
const ringRadius = dotRadius + 4;
const isMoveNotClick = ref(false);
let moveCleanup = null;

const startPosition = reactive({ x: -1, y: -1 });

const { width: elementWidth, height: elementHeight } = useElementSize(rootRef);
const { pressed, currentPosition, cancel } = usePointermove(rootRef);
const { escape } = useMagicKeys();

const arrowPath = computed(() => {
  if (startPosition.x < 0 || startPosition.y < 0 || !isMoveNotClick.value) return '';

  const start = startPosition;
  const end = currentPosition;

  const angle = Math.atan2(end.y - start.y, end.x - start.x);
  const baseWidth = 16;
  const tipWidth = 8;
  const arrowHeadLength = 16;
  const arrowHeadWidth = 32;

  // Base points
  const baseP1 = {
    x: start.x + (Math.cos(angle + Math.PI / 2) * baseWidth) / 2,
    y: start.y + (Math.sin(angle + Math.PI / 2) * baseWidth) / 2,
  };
  const baseP2 = {
    x: start.x + (Math.cos(angle - Math.PI / 2) * baseWidth) / 2,
    y: start.y + (Math.sin(angle - Math.PI / 2) * baseWidth) / 2,
  };

  // Point where arrow head begins
  const tipBase = {
    x: end.x - Math.cos(angle) * arrowHeadLength,
    y: end.y - Math.sin(angle) * arrowHeadLength,
  };

  // Arrow head base points
  const tipBaseP1 = {
    x: tipBase.x + (Math.cos(angle + Math.PI / 2) * tipWidth) / 2,
    y: tipBase.y + (Math.sin(angle + Math.PI / 2) * tipWidth) / 2,
  };
  const tipBaseP2 = {
    x: tipBase.x + (Math.cos(angle - Math.PI / 2) * tipWidth) / 2,
    y: tipBase.y + (Math.sin(angle - Math.PI / 2) * tipWidth) / 2,
  };

  // Arrow head wing points
  const wingP1 = {
    x: tipBase.x + (Math.cos(angle + Math.PI / 2) * arrowHeadWidth) / 2,
    y: tipBase.y + (Math.sin(angle + Math.PI / 2) * arrowHeadWidth) / 2,
  };
  const wingP2 = {
    x: tipBase.x + (Math.cos(angle - Math.PI / 2) * arrowHeadWidth) / 2,
    y: tipBase.y + (Math.sin(angle - Math.PI / 2) * arrowHeadWidth) / 2,
  };

  return `
    M ${baseP1.x} ${baseP1.y}
    L ${tipBaseP1.x} ${tipBaseP1.y}
    L ${wingP1.x} ${wingP1.y}
    L ${end.x} ${end.y}
    L ${wingP2.x} ${wingP2.y}
    L ${tipBaseP2.x} ${tipBaseP2.y}
    L ${baseP2.x} ${baseP2.y}
    Z
  `;
});

const { trigger: moveCamera } = useLoadingRequest(api.moveCamera);
const { trigger: moveToPoint } = useLoadingRequest(api.moveToPoint);
const throttledMoveCamera = throttle(moveCamera, 100);

const triggerMoveCamera = () => {
  const offsetX = currentPosition.x - startPosition.x;
  const offsetY = currentPosition.y - startPosition.y;

  if (!isMoveNotClick.value) {
    const isWidthThresholdInPercentage = MOVE_WIDTH_THRESHOLD < 1;
    const isHeightThresholdInPercentage = MOVE_HEIGHT_THRESHOLD < 1;
    const widthThreshold = isWidthThresholdInPercentage
      ? MOVE_WIDTH_THRESHOLD * elementWidth.value
      : MOVE_WIDTH_THRESHOLD;
    const heightThreshold = isHeightThresholdInPercentage
      ? MOVE_HEIGHT_THRESHOLD * elementHeight.value
      : MOVE_HEIGHT_THRESHOLD;

    if (Math.abs(offsetX) < widthThreshold && Math.abs(offsetY) < heightThreshold) return;

    isMoveNotClick.value = true;
  }

  const panDirection = offsetX > 0 ? 'right' : 'left';
  const tiltDirection = offsetY > 0 ? 'down' : 'up';

  // Calculate the linear proportion of the offset to half of the rectangle's dimension.
  const panRatio = Math.abs(offsetX / (elementWidth.value / 2));
  const tiltRatio = Math.abs(offsetY / (elementHeight.value / 2));

  // Scale the ratio to the maximum speed. Assuming maximum speed is 10000.
  const maxSpeed = 3000;
  const panSpeed = Math.round(panRatio * maxSpeed);
  const tiltSpeed = Math.round(tiltRatio * maxSpeed);

  const pan = {
    direction: panDirection,
    speed: panSpeed,
  };
  const tilt = {
    direction: tiltDirection,
    speed: tiltSpeed,
  };

  throttledMoveCamera({ pan, tilt });
};

const startMove = () => {
  isMoveNotClick.value = false;

  moveCleanup = watch(currentPosition, triggerMoveCamera);

  startPosition.x = currentPosition.x;
  startPosition.y = currentPosition.y;
};

const stopMove = () => {
  moveCleanup?.();
  moveCleanup = null;

  if (!isMoveNotClick.value) return;

  const pan = {
    direction: 'stop',
    speed: 5000,
  };
  const tilt = {
    direction: 'stop',
    speed: 5000,
  };

  throttledMoveCamera({ pan, tilt });
};

const setPosition = () => {
  if (isMoveNotClick.value) return;

  moveToPoint({
    x: currentPosition.x / elementWidth.value,
    y: currentPosition.y / elementHeight.value,
  });
};

watchEffect(() => {
  if (escape.value) {
    cancel();
  }
});

watch(pressed, (value) => {
  if (value) {
    startMove();
  } else {
    stopMove();
  }
});
</script>
