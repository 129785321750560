<template>
  <div class="tw-space-y-5">
    <Heading level="h3" level-class="h4">{{ $t('settings_modal.preview.title') }}</Heading>

    <div class="tw-grid tw-grid-cols-1 tw-gap-5 sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4">
      <FormField
        v-model="previewQuality"
        type="select"
        :disabled="locked || disabled"
        :label="$t('settings_modal.preview.preview_quality.label')"
      >
        <option v-for="option in previewQualityOptions" :key="option.value" :value="option.value">
          {{ option.label }}
        </option>
      </FormField>

      <FormField
        v-model.number="fps"
        type="number"
        :disabled="locked || disabled"
        :min="controller.output_gui_video_fps.min"
        :max="controller.output_gui_video_fps.max"
        :step="controller.output_gui_video_fps.step"
        :label="
          $t('settings_modal.preview.fps.label', {
            from: controller.output_gui_video_fps.min,
            to: controller.output_gui_video_fps.max,
          })
        "
      />

      <FormField
        v-model.number="encoderQuality"
        type="number"
        :disabled="locked || disabled"
        :min="controller.output_gui_video_quality.min"
        :max="controller.output_gui_video_quality.max"
        :step="controller.output_gui_video_quality.step"
        :label="
          $t('settings_modal.preview.encoder_quality.label', {
            from: controller.output_gui_video_quality.min,
            to: controller.output_gui_video_quality.max,
          })
        "
      />

      <FormField
        v-model="encoderType"
        type="select"
        :disabled="locked || disabled"
        :label="$t('settings_modal.preview.encoder_type.label')"
      >
        <option v-for="option in controller.output_gui_video_encoder.options" :key="option" :value="option">
          {{ option }}
        </option>
      </FormField>

      <FormField :value="bitrate" readonly :label="$t('settings_modal.preview.approximate_bitrate.label')" />
    </div>
  </div>
</template>

<script setup>
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import { FormField, Heading } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();

const previewQualityOptions = {
  low: {
    label: t('settings_modal.preview.preview_quality.options.low'),
    value: 'low',
    fps: 5,
    encoderQuality: 10,
    encoderType: 'webp',
  },
  medium: {
    label: t('settings_modal.preview.preview_quality.options.medium'),
    value: 'medium',
    fps: 12,
    encoderQuality: 30,
    encoderType: 'webp',
  },
  high: {
    label: t('settings_modal.preview.preview_quality.options.high'),
    value: 'high',
    fps: 12,
    encoderQuality: 60,
    encoderType: 'webp',
  },
};

const appStore = useAppStore();
const { locked } = storeToRefs(appStore);
const dataStore = useDataStore();
const { controller } = storeToRefs(dataStore);

const fps = defineModel('fps');
const encoderQuality = defineModel('encoderQuality');
const encoderType = defineModel('encoderType');

const previewQuality = ref('');

const bitrate = computed(() => {
  switch (encoderType.value) {
    case 'webp':
      return Math.round((5 * encoderQuality.value + 50) * fps.value);
    case 'jpg':
      return Math.round((6 * encoderQuality.value + 140) * fps.value);
    default:
      return 'unknown';
  }
});

const resetPreviewQuality = () => {
  switch (controller.value.output_gui_video_quality.value) {
    case 10:
      previewQuality.value = 'low';
      break;
    case 30:
      previewQuality.value = 'medium';
      break;
    case 60:
      previewQuality.value = 'high';
      break;
    default:
      previewQuality.value = 'medium';
  }
};

watch(previewQuality, (newQuality) => {
  const {
    fps: defaultFps,
    encoderQuality: defaultEncoderQuality,
    encoderType: defaultEncoderType,
  } = previewQualityOptions[newQuality];

  fps.value = defaultFps;
  encoderQuality.value = defaultEncoderQuality;
  encoderType.value = defaultEncoderType;
});

onMounted(() => {
  resetPreviewQuality();
});

const api = { resetPreviewQuality };

defineExpose(api);
</script>
