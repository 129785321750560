import { isVariableDefinedNotNull, toCamelCase, urlParam } from '@slideslive/fuse-kit/utils';
import { unref } from 'vue';

import BaseDataFetcher from './BaseDataFetcher';
import useDataStore from './stores/data';
import buildNateUrl from './utils/build_nate_url';

const METHODS = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete',
};

export default class RealDataFetcher extends BaseDataFetcher {
  constructor() {
    super();

    this.apiBaseUrl = buildNateUrl();

    if (
      !isVariableDefinedNotNull(urlParam('nate_host')) &&
      !import.meta.env.PROD &&
      urlParam('use_real_data') === 'true'
    ) {
      this.apiBaseUrl = '/vpn_api';
    }

    // eslint-disable-next-line no-console
    console.info(`Using RealDataFetcher with apiBaseUrl set to '${this.apiBaseUrl}'.`);
  }

  async #makeRequest(
    method,
    path,
    data = null,
    { key = null, abortController = null, contentType = 'application/json', ...params } = {},
  ) {
    const dataStore = useDataStore();
    const { sessionId } = dataStore;

    if (key) {
      path = path.replace(`:${key}`, params[toCamelCase(key)]);
    }

    const url = `${this.apiBaseUrl}${path}`;
    const headers = {
      'Content-Type': contentType,
    };

    if (unref(sessionId)) {
      headers['X-SlidesLive-NATE-Session-Id'] = unref(sessionId);
    }

    const fetchOptions = {
      method: method.toUpperCase(),
      headers,
      signal: abortController?.signal,
    };

    if (data) {
      fetchOptions.body = JSON.stringify(data);
    }

    const response = await fetch(url, fetchOptions);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    if (contentType === 'application/json') {
      return response.json();
    }

    if (contentType === 'text/markdown') {
      return response.text();
    }

    return response;
  }

  async get(path, data, options) {
    return this.#makeRequest(METHODS.GET, path, data, options);
  }

  async post(path, data, options) {
    return this.#makeRequest(METHODS.POST, path, data, options);
  }

  async patch(path, data, options) {
    return this.#makeRequest(METHODS.PATCH, path, data, options);
  }

  async put(path, data, options) {
    return this.#makeRequest(METHODS.PUT, path, data, options);
  }

  async delete(path, data, options) {
    return this.#makeRequest(METHODS.DELETE, path, data, options);
  }
}
