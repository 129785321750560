<template>
  <Move v-if="controlAction === 'move_pan_tilt'" />
  <DrawTrackingZone v-else-if="controlAction === 'draw_tz'" />
  <ClickActions v-else />
</template>

<script setup>
import useAppStore from '@/stores/app';
import { storeToRefs } from 'pinia';

import ClickActions from './ClickActions.vue';
import DrawTrackingZone from './DrawTrackingZone.vue';
import Move from './Move.vue';

const appStore = useAppStore();
const { controlAction } = storeToRefs(appStore);
</script>
