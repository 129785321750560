<template>
  <Teleport to="#teleport-container">
    <Modal v-model="show" @opening="reset" @close="reset">
      <ModalContent ref="content">
        <template #header>
          <Heading level="h2" level-class="h3">{{ $t('preview_modal.title') }}</Heading>
        </template>

        <template #footer="{ reject }">
          <div class="tw-flex tw-w-full tw-flex-wrap tw-items-center tw-justify-between tw-gap-5">
            <ValueSwitcher v-model="selectedPreview" :options="previewOptions" />
            <Button color="transparent" outlined @click="reject">{{ $t('close') }}</Button>
          </div>
        </template>

        <div ref="imageWrapper" :style="imageWrapperStyle" class="tw-aspect-video tw-w-full">
          <img v-show="show" :src="multiviewUrl" class="tw-size-full tw-object-cover" />
        </div>
      </ModalContent>
    </Modal>
  </Teleport>
</template>

<script setup>
import useAppStore from '@/stores/app';
import buildNateUrl from '@/utils/build_nate_url';
import { Button, Heading, Modal, ModalContent, ValueSwitcher } from '@slideslive/fuse-kit/vue';
import { useWindowSize } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed, ref, useTemplateRef, watch, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const appStore = useAppStore();
const { setDisabledPreviewAndMultiview } = appStore;
const { viewZoom } = storeToRefs(appStore);

const show = defineModel();
const contentRef = useTemplateRef('content');
const imageWrapperRef = useTemplateRef('imageWrapper');

const { height: windowHeight, width: windowWidth } = useWindowSize();

const previewOptions = [
  { value: 220, label: t('preview_modal.multiview') },
  { value: 3, label: t('preview_modal.main_camera') },
  { value: 4, label: t('preview_modal.wideshot_camera') },
  { value: 5, label: t('preview_modal.slides') },
  { value: 6, label: t('preview_modal.input_4') },
  { value: 0, label: t('preview_modal.all') },
];

const selectedPreview = ref(previewOptions[0].value);
const imageWrapperStyle = ref({});

const multiviewUrl = computed(() =>
  show.value ? buildNateUrl(`/streams/multiview?width=1344&q=60&fps=12&idx=${selectedPreview.value}`) : null,
);

const updateSize = () => {
  imageWrapperStyle.value = {};

  if (!contentRef.value || !imageWrapperRef.value || !contentRef.value || !imageWrapperRef.value) return;

  const maxWidth = windowWidth.value / viewZoom.value - 2 * 16 - 2 * 24;
  const maxHeight =
    windowHeight.value / viewZoom.value -
    2 * 16 -
    Math.ceil(contentRef.value.el.clientHeight - imageWrapperRef.value.clientHeight);

  const width = (maxWidth * 9) / 16 < maxHeight ? maxWidth : Math.floor((maxHeight / 9) * 16);
  const height = Math.floor((width * 9) / 16);

  imageWrapperStyle.value = {
    width: `${width}px`,
    height: `${height}px`,
  };
};

const reset = () => {
  selectedPreview.value = previewOptions[0].value;

  updateSize();
};

watch(show, (value) => {
  setDisabledPreviewAndMultiview(value);
});

watchEffect(() => {
  updateSize();
});
</script>
