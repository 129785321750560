<template>
  <header
    ref="root"
    v-bind="$attrs"
    class="tw-flex tw-min-h-20 tw-items-center tw-justify-between tw-border-b-2 tw-border-purple/25 tw-py-2"
  >
    <div class="tw-flex tw-items-center tw-space-x-10">
      <div>
        <Heading level="h1" level-class="h2">{{ title }}</Heading>
        <Text v-if="app.track_name" class="tw-mt-1">{{ app.track_name }}</Text>
      </div>

      <HeaderNavigation class="tw-hidden lg:tw-block" @open-changelog="openChangelog" />
    </div>

    <div class="tw-flex tw-items-center tw-space-x-2">
      <Icon
        v-if="connectionIcon"
        :name="connectionIcon"
        :class="['tw-mr-5 tw-hidden tw-size-6 tw-fill-current sm:tw-block', connectionColor]"
      />

      <Dropdown
        class="lg:tw-hidden"
        :overlay="false"
        :trigger-attrs="{
          icon: 'menu',
          shape: 'rounded',
          size: 'large',
          color: 'semiTransparentWhite',
          iconButton: true,
        }"
      >
        <template #menu>
          <HeaderNavigation dropdown @open-changelog="openChangelog" />
        </template>
      </Dropdown>

      <CircularButton
        icon="settings"
        color="semiTransparentWhite"
        shape="rounded"
        size="large"
        :aria-label="$t('settings')"
        @click="openSettings"
      />

      <CircularButton
        :icon="lockIcon"
        :color="lockColor"
        shape="rounded"
        size="large"
        :aria-label="$t('lock')"
        @click="toggleLock"
      />
    </div>
  </header>

  <ChangelogModal v-model="changelogOpen" />
  <SettingsModal v-model="settingsOpen" />
</template>

<script setup>
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import { CircularButton, Dropdown, Heading, Icon, Text } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { computed, readonly, ref, useTemplateRef } from 'vue';
import { useI18n } from 'vue-i18n';

import ChangelogModal from './ChangelogModal.vue';
import HeaderNavigation from './headerNavigation/HeaderNavigation.vue';
import SettingsModal from './settingsModal/SettingsModal.vue';

const { t } = useI18n();
const appStore = useAppStore();
const { setLocked } = appStore;
const { locked } = storeToRefs(appStore);
const dataStore = useDataStore();
const { app, router } = storeToRefs(dataStore);

const root = useTemplateRef('root');

const changelogOpen = ref(false);
const settingsOpen = ref(false);

const title = computed(() => app.value.station_name.slice(0, 25) || t('default_station_name'));

const connectionIcon = computed(() => {
  switch (router.value.wan_interface) {
    case 'wifi':
      return router.value.internet_connected ? 'wifi' : 'wifi-off';
    case 'ethernet':
      return 'lan';
    default:
      return null;
  }
});

const connectionColor = computed(() => {
  if (router.value.wan_interface !== 'ethernet') return '';

  return router.value.internet_connected ? 'tw-text-state-succ' : 'tw-text-state-err';
});

const lockIcon = computed(() => (locked.value ? 'lock' : 'lock-open'));
const lockColor = computed(() => (locked.value ? 'red' : 'semiTransparentWhite'));

const openChangelog = () => {
  changelogOpen.value = true;
};

const openSettings = () => {
  settingsOpen.value = true;
};

const toggleLock = () => {
  setLocked(!locked.value);
};

defineExpose({ el: readonly(root) });
</script>
