<template>
  <div
    ref="root"
    :class="[
      'tw-absolute tw-inset-0 tw-z-10 tw-cursor-crosshair',
      !locked && !previewActionDisabled && 'tw-touch-none',
    ]"
  >
    <div
      v-if="pressed"
      :style="trackingZoneStyle"
      class="tw-absolute tw-border-2 tw-border-white tw-shadow tw-shadow-dark"
    ></div>
  </div>
</template>

<script setup>
import api from '@/api';
import useLoadingRequest from '@/composables/loading_request';
import usePointermove from '@/composables/pointermove';
import { TRACKING_ZONE_HEIGHT_THRESHOLD, TRACKING_ZONE_WIDTH_THRESHOLD } from '@/constants';
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import { useElementSize, useMagicKeys } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed, reactive, useTemplateRef, watch, watchEffect } from 'vue';

const appStore = useAppStore();
const { locked, previewActionDisabled } = storeToRefs(appStore);
const dataStore = useDataStore();
const { zones } = storeToRefs(dataStore);

const rootRef = useTemplateRef('root');

const startPosition = reactive({ x: -1, y: -1 });

const { width: elementWidth, height: elementHeight } = useElementSize(rootRef);
const { pressed, currentPosition, cancel } = usePointermove(rootRef);
const { escape } = useMagicKeys();

const trackingZoneStyle = computed(() => {
  const left = Math.min(startPosition.x, currentPosition.x);
  const top = Math.min(startPosition.y, currentPosition.y);
  const width = Math.abs(startPosition.x - currentPosition.x);
  const height = Math.abs(startPosition.y - currentPosition.y);

  return {
    left: `${left}px`,
    top: `${top}px`,
    width: `${width}px`,
    height: `${height}px`,
  };
});

const { trigger: createTrackingZone } = useLoadingRequest(api.createTrackingZone);
const { trigger: patchTrackingZone } = useLoadingRequest(api.patchTrackingZone);

const startDrawTrackingZone = () => {
  startPosition.x = currentPosition.x;
  startPosition.y = currentPosition.y;
};

const stopDrawTrackingZone = () => {
  const startPercentageX = startPosition.x / elementWidth.value;
  const startPercentageY = startPosition.y / elementHeight.value;
  const percentageX = currentPosition.x / elementWidth.value;
  const percentageY = currentPosition.y / elementHeight.value;

  const leftTop = {
    x: Math.min(startPercentageX, percentageX),
    y: Math.min(startPercentageY, percentageY),
  };
  const rightTop = {
    x: Math.max(startPercentageX, percentageX),
    y: Math.min(startPercentageY, percentageY),
  };
  const leftBottom = {
    x: Math.min(startPercentageX, percentageX),
    y: Math.max(startPercentageY, percentageY),
  };
  const rightBottom = {
    x: Math.max(startPercentageX, percentageX),
    y: Math.max(startPercentageY, percentageY),
  };

  const isWidthThresholdInPercentage = TRACKING_ZONE_WIDTH_THRESHOLD < 1;
  const isHeightThresholdInPercentage = TRACKING_ZONE_HEIGHT_THRESHOLD < 1;
  const widthThreshold = isWidthThresholdInPercentage
    ? TRACKING_ZONE_WIDTH_THRESHOLD
    : TRACKING_ZONE_WIDTH_THRESHOLD / elementWidth.value;
  const heightThreshold = isHeightThresholdInPercentage
    ? TRACKING_ZONE_HEIGHT_THRESHOLD
    : TRACKING_ZONE_HEIGHT_THRESHOLD / elementHeight.value;

  if (rightTop.x - leftTop.x < widthThreshold || leftBottom.y - leftTop.y < heightThreshold) return;

  const payload = {
    left_top: leftTop,
    right_top: rightTop,
    left_bottom: leftBottom,
    right_bottom: rightBottom,
  };

  if (zones.value.length) {
    patchTrackingZone(payload, {
      key: 'zone_id',
      zoneId: zones.value[0].zone_id,
    });
  } else {
    createTrackingZone(payload);
  }
};

watchEffect(() => {
  if (escape.value) {
    cancel();
  }
});

watch(pressed, (value) => {
  if (value) {
    startDrawTrackingZone();
  } else if (!escape.value) {
    stopDrawTrackingZone();
  }
});
</script>
