<template>
  <div ref="root" class="tw-absolute tw-inset-0 tw-z-10 tw-cursor-pointer" @click="trigerAction">
    <div
      v-if="showRipple"
      class="ripple-animation tw-pointer-events-none tw-absolute tw-size-5 tw--translate-x-1/2 tw--translate-y-1/2 tw-rounded-full"
      :style="rippleStyle"
    ></div>
  </div>
</template>

<script setup>
import api from '@/api';
import useLoadingRequest from '@/composables/loading_request';
import usePointermove from '@/composables/pointermove';
import useAppStore from '@/stores/app';
import { useElementSize } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed, onBeforeUnmount, reactive, ref, useTemplateRef } from 'vue';

const appStore = useAppStore();
const { controlAction } = storeToRefs(appStore);

const rootRef = useTemplateRef('root');

const showRipple = ref(false);
const ripplePosition = reactive({ x: 0, y: 0 });
let rippleTimeout = null;

const rippleStyle = computed(() => ({
  left: `${ripplePosition.x}px`,
  top: `${ripplePosition.y}px`,
}));

const { width: elementWidth, height: elementHeight } = useElementSize(rootRef);
const { currentPosition } = usePointermove(rootRef);

const { trigger: setFocusToPoint } = useLoadingRequest(api.setFocusToPoint);
const { trigger: addSpeaker } = useLoadingRequest(api.addSpeaker);
const { trigger: ignoreSpeaker } = useLoadingRequest(api.ignoreSpeaker);

const trigerAction = async () => {
  ripplePosition.x = currentPosition.x;
  ripplePosition.y = currentPosition.y;
  showRipple.value = true;

  const relativeX = currentPosition.x / elementWidth.value;
  const relativeY = currentPosition.y / elementHeight.value;

  switch (controlAction.value) {
    case 'push_focus':
      setFocusToPoint({ x: relativeX, y: relativeY });
      break;
    case 'add_speaker':
      addSpeaker({ x: relativeX, y: relativeY });
      break;
    case 'ignore_speaker':
      ignoreSpeaker({ x: relativeX, y: relativeY });
      break;
    default:
      console.warn('Unknown preview action', controlAction.value);
  }

  clearTimeout(rippleTimeout);
  rippleTimeout = setTimeout(() => {
    showRipple.value = false;
  }, 500);
};

onBeforeUnmount(() => {
  clearTimeout(rippleTimeout);
});
</script>

<style scoped>
.ripple-animation {
  animation: ripple-ping 0.5s cubic-bezier(0.4, 0, 0.2, 1) forwards;

  background: radial-gradient(
    circle,
    adjust-alpha(theme('colors.white'), 0.8, true) 0%,
    adjust-alpha(theme('colors.white'), 0.4, true) 40%,
    adjust-alpha(theme('colors.white'), 0, true) 70%
  );
}

@keyframes ripple-ping {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    transform: translate(-50%, -50%) scale(4);
    opacity: 0;
  }
}
</style>
