<template>
  <component :is="tag" class="tw-relative tw-aspect-[58/72]">
    <div
      class="tw-absolute tw-left-0 tw-top-0 tw-flex tw-w-full tw-items-center tw-justify-between tw-bg-dark/50 tw-p-0.5"
    >
      <Text size="xsmall">[{{ speaker.speaker_id }}]</Text>

      <CircularButton
        :loading="deleting"
        :disabled="locked"
        icon="close"
        size="xsmall"
        color="transparentWhite"
        class="tw--m-0.5 tw-size-4"
        :icon-attrs="{ class: 'tw-size-3' }"
        @click="triggerDelete"
      />
    </div>

    <img :src="imageSrc" class="tw-size-full tw-object-cover" />
  </component>
</template>

<script setup>
import useAppStore from '@/stores/app';
import buildNateUrl from '@/utils/build_nate_url';
import { CircularButton, Text } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

const props = defineProps({
  tag: {
    type: String,
    default: 'div',
  },
  deleting: {
    type: Boolean,
    default: false,
  },
  speaker: {
    type: Object,
    required: true,
  },
  onDelete: {
    type: Function,
    default: undefined,
  },
});

const emit = defineEmits(['delete']);

const appStore = useAppStore();
const { locked } = storeToRefs(appStore);

const imageSrc = computed(() => buildNateUrl(props.speaker.image_url));

const triggerDelete = () => {
  emit('delete', props.speaker.speaker_id);
};
</script>
