<template>
  <TabPanel v-bind="$attrs" class="tw-flex tw-h-full tw-flex-col tw-items-start tw-gap-5 sm:tw-flex-row">
    <div class="tw-flex tw-flex-wrap tw-items-center tw-gap-5 sm:tw-flex-col sm:tw-items-start">
      <Button
        :loading="startAllRecordersLoading"
        :disabled="locked"
        color="red"
        class="tw-whitespace-nowrap"
        @click="startAllRecorders"
      >
        {{ $t('settings_tabs.recorders.start_all_rec') }}
      </Button>

      <Button
        :loading="stopAllRecordersLoading"
        :disabled="locked"
        color="transparentRed"
        outlined
        class="tw-whitespace-nowrap"
        @click="stopAllRecorders"
      >
        {{ $t('settings_tabs.recorders.stop_all_rec') }}
      </Button>

      <Text size="large">
        <Link tag="button" scheme="underline" @click="openSettings">
          {{ $t('settings') }}
        </Link>
      </Text>
    </div>

    <div class="tw-max-h-full tw-overflow-auto">
      <Table>
        <RecorderItem
          v-for="recorder in recorders"
          :key="recorder.recorder_id"
          :recorder
          :action-loading="loadingRecorders.includes(recorder.recorder_id)"
          @start="triggerStartRecorder(recorder.recorder_id)"
          @stop="triggerStopRecorder(recorder.recorder_id)"
        />
      </Table>
    </div>
  </TabPanel>

  <RecordersModal v-model="settingsOpen" />
</template>

<script setup>
import api from '@/api';
import useDraftState from '@/composables/draft_state';
import useLoadingRequest from '@/composables/loading_request';
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import { Button, Link, TabPanel, Table, Text } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import RecorderItem from './RecorderItem.vue';
import RecordersModal from './RecordersModal.vue';

const appStore = useAppStore();
const { locked } = storeToRefs(appStore);
const dataStore = useDataStore();
const { recorders } = storeToRefs(dataStore);

const settingsOpen = ref(false);
const loadingStartRecorders = ref([]);
const loadingStopRecorders = ref([]);

const loadingRecorders = computed(() => [...loadingStartRecorders.value, ...loadingStopRecorders.value]);
const draftRecorders = computed(() =>
  recorders.value.map((recorder) => {
    if (loadingStartRecorders.value.includes(recorder.recorder_id)) {
      return { ...recorder, status: 'recording' };
    }

    if (loadingStopRecorders.value.includes(recorder.recorder_id)) {
      return { ...recorder, status: 'stand-by' };
    }

    return recorder;
  }),
);

const { loading: startAllRecordersLoading, trigger: startAllRecorders } = useLoadingRequest(api.startAllRecorders, {
  withoutData: true,
});
const { loading: stopAllRecordersLoading, trigger: stopAllRecorders } = useLoadingRequest(api.stopAllRecorders, {
  withoutData: true,
});

const { trigger: startRecorder } = useDraftState(api.startRecorder, {
  withoutData: true,
  lazy: true,
  statePath: ['recorders'],
  draftStateValue: draftRecorders,
});

const { trigger: stopRecorder } = useDraftState(api.stopRecorder, {
  withoutData: true,
  lazy: true,
  statePath: ['recorders'],
  draftStateValue: draftRecorders,
});

const triggerStartRecorder = async (recorderId) => {
  loadingStartRecorders.value = [...loadingStartRecorders.value, recorderId];
  await startRecorder({ key: 'recorder_id', recorderId });
  loadingStartRecorders.value = loadingStartRecorders.value.filter((id) => id !== recorderId);
};

const triggerStopRecorder = async (recorderId) => {
  loadingStopRecorders.value = [...loadingStopRecorders.value, recorderId];
  await stopRecorder({ key: 'recorder_id', recorderId });
  loadingStopRecorders.value = loadingStopRecorders.value.filter((id) => id !== recorderId);
};

const openSettings = () => {
  settingsOpen.value = true;
};
</script>
