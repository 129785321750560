export default class DataFetcher {
  #throwError(message) {
    throw new Error(`[${this.constructor.name}] ${message}`);
  }

  async get() {
    this.#throwError('get method not implemented');
  }

  async post() {
    this.#throwError('post method not implemented');
  }

  async patch() {
    this.#throwError('patch method not implemented');
  }

  async put() {
    this.#throwError('put method not implemented');
  }

  async delete() {
    this.#throwError('delete method not implemented');
  }
}
