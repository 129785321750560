import { isVariableDefinedNotNull, urlParam } from '@slideslive/fuse-kit/utils';

import BaseDataFetcher from './BaseDataFetcher';
import MockDataFetcher from './MockDataFetcher';
import RealDataFetcher from './RealDataFetcher';

export default class DataFetcher extends BaseDataFetcher {
  constructor(fetcher = null) {
    super();

    let defaultFetcher;

    if (
      isVariableDefinedNotNull(urlParam('nate_host')) ||
      import.meta.env.PROD ||
      urlParam('use_real_data') === 'true'
    ) {
      defaultFetcher = new RealDataFetcher();
    } else {
      defaultFetcher = new MockDataFetcher();
    }

    this.fetcher = fetcher || defaultFetcher;
  }

  async get(...args) {
    return this.fetcher.get(...args);
  }

  async post(...args) {
    return this.fetcher.post(...args);
  }

  async patch(...args) {
    return this.fetcher.patch(...args);
  }

  async put(...args) {
    return this.fetcher.put(...args);
  }

  async delete(...args) {
    return this.fetcher.delete(...args);
  }
}
