<template>
  <TableRow with-border>
    <TableCell class="tw-w-28 sm:tw-w-50">
      <div class="tw-flex tw-items-center tw-gap-2">
        <Text class="tw-font-black">{{ recorder.recorder_name }}</Text>

        <label v-if="recorder.error" v-tooltip="props.recorder.error">
          <Icon name="warning" class="tw-size-4 tw-fill-current tw-text-state-warn" />
        </label>
      </div>
    </TableCell>

    <TableCell class="tw-w-10">
      <CircularButton
        v-if="recorder.status === 'recording'"
        :loading="actionLoading"
        :disabled="locked"
        shape="rounded"
        size="xsmall"
        color="red"
        @click="emit('stop')"
      >
        <div class="tw-size-3 tw-bg-red-dark"></div>
      </CircularButton>

      <CircularButton
        v-else-if="recorder.status === 'stand-by'"
        :loading="actionLoading"
        :disabled="locked"
        size="xsmall"
        color="red"
        @click="emit('start')"
      >
        <div class="tw-size-3 tw-rounded-full tw-bg-red-dark"></div>
      </CircularButton>
    </TableCell>

    <TableCell>
      <Text size="small">
        <span :class="['tw-font-bold', statusColor]">{{ recorder.status }}</span
        ><template v-if="recorder.message">, {{ recorder.message }}</template>
      </Text>
    </TableCell>

    <TableCell class="tw-w-12">
      <CircularButton
        v-if="recorder.interface_url"
        icon="double-arrow"
        size="xsmall"
        color="transparentWhite"
        tag="a"
        :href="recorder.interface_url"
        target="_blank"
      />
    </TableCell>
  </TableRow>
</template>

<script setup>
import useAppStore from '@/stores/app';
import { CircularButton, Icon, TableCell, TableRow, Text } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

const props = defineProps({
  recorder: {
    type: Object,
    required: true,
  },
  actionLoading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['start', 'stop']);

const appStore = useAppStore();
const { locked } = storeToRefs(appStore);

const statusColor = computed(() => {
  if (props.recorder.status === 'recording') {
    return 'tw-text-state-err';
  }

  if (props.recorder.status === 'stand-by') {
    return 'tw-text-state-succ';
  }

  return 'tw-text-white/50';
});
</script>
