<template>
  <ControlSection>
    <div class="tw-flex tw-flex-col tw-space-y-2">
      <div class="tw-flex tw-flex-wrap tw-items-center tw-gap-5">
        <FormTag
          v-model="model"
          :disabled="locked"
          type="radio"
          name="controlAction"
          value="add_speaker"
          :label="$t('control_modes.speakers.add_speaker')"
          class="tw-whitespace-nowrap"
        />

        <div class="tw-flex tw-flex-wrap tw-items-center tw-gap-5">
          <Checkbox
            v-tooltip="autoSpeakerSelectionTooltip"
            v-model="autoSpeakerSelection"
            :disabled="locked || !zones.length"
            type="switch"
            :right-label="$t('control_modes.speakers.auto_selection.label')"
            @update:model-value="setAutoSpeakerSelection({ auto_speaker_selection: $event })"
          />

          <Checkbox
            v-if="controlMode === 'tracker'"
            :model-value="controller.strict_mode"
            :disabled="locked"
            type="switch"
            :right-label="$t('control_modes.speakers.strict_mode.label')"
            @update:model-value="setStrictMode({ controller: { strict_mode: $event } })"
          />
        </div>
      </div>

      <div class="tw-flex tw-flex-wrap tw-items-center tw-gap-5">
        <FormTag
          v-model="model"
          :disabled="locked"
          type="radio"
          name="controlAction"
          value="ignore_speaker"
          :label="$t('control_modes.speakers.ignore_speaker')"
          class="tw-whitespace-nowrap"
        />

        <div class="tw-flex tw-flex-wrap tw-items-center tw-gap-5">
          <Checkbox
            v-tooltip="autoSpeakerRemovalTooltip"
            v-model="autoSpeakerRemoval"
            :disabled="locked || !zones.length"
            type="switch"
            :right-label="$t('control_modes.speakers.auto_removal.label')"
            @update:model-value="setAutoSpeakerRemoval({ auto_speaker_removal: $event })"
          />

          <Checkbox
            v-if="controlMode === 'tracker'"
            :model-value="controller.single_speaker_mode"
            :disabled="locked"
            type="switch"
            :right-label="$t('control_modes.speakers.single_speaker_mode.label')"
            @update:model-value="setSingleSpeakerMode({ controller: { single_speaker_mode: $event } })"
          />
        </div>
      </div>
    </div>

    <div class="tw-flex tw-items-center tw-gap-x-2"></div>
  </ControlSection>
</template>

<script setup>
import api from '@/api';
import useDraftState from '@/composables/draft_state';
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import { Checkbox, FormTag } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import ControlSection from './ControlSection.vue';

defineProps({
  controlMode: {
    type: String,
    required: true,
  },
});

const { t } = useI18n();
const appStore = useAppStore();
const { locked, tooltips } = storeToRefs(appStore);
const dataStore = useDataStore();
const { controller, zones } = storeToRefs(dataStore);

const model = defineModel();

const autoSpeakerSelection = ref(controller.value.auto_speaker_selection);
const autoSpeakerRemoval = ref(controller.value.auto_speaker_removal);

const autoSpeakerSelectionTooltip = computed(() => ({
  disabled: !tooltips.value,
  content: t('control_modes.speakers.auto_selection.tooltip'),
}));
const autoSpeakerRemovalTooltip = computed(() => ({
  disabled: !tooltips.value,
  content: t('control_modes.speakers.auto_removal.tooltip'),
}));

const { trigger: setAutoSpeakerSelection } = useDraftState(api.setAutoSpeakerSelection, {
  statePath: ['controller', 'auto_speaker_selection'],
  draftStateValue: autoSpeakerSelection,
});
const { trigger: setAutoSpeakerRemoval } = useDraftState(api.setAutoSpeakerRemoval, {
  statePath: ['controller', 'auto_speaker_removal'],
  draftStateValue: autoSpeakerRemoval,
});
const { trigger: setStrictMode } = useDraftState(api.patchState, {
  statePathToClear: ['controller', 'strict_mode'],
});
const { trigger: setSingleSpeakerMode } = useDraftState(api.patchState, {
  statePathToClear: ['controller', 'single_speaker_mode'],
});

watch(
  () => controller.value.auto_speaker_selection,
  (value) => {
    autoSpeakerSelection.value = value;
  },
);

watch(
  () => controller.value.auto_speaker_removal,
  (value) => {
    autoSpeakerRemoval.value = value;
  },
);
</script>
