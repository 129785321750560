import { SLIDER_RESET_TO_STATE_DEBOUNCE_INTERVAL, SLIDER_THROTTLE_INTERVAL } from '@/constants';
import { debounce, throttle } from 'lodash';
import { watch } from 'vue';

const useSliderValue = ({ queryFn, resetToStateFn, stateValueToWatch }) => {
  let triggerDebouncedResetToStateOnStateChange = false;
  let requestPendingCounter = 0;

  const debouncedResetToState = debounce(() => {
    resetToStateFn();

    if (!requestPendingCounter) {
      triggerDebouncedResetToStateOnStateChange = false;
    }
  }, SLIDER_RESET_TO_STATE_DEBOUNCE_INTERVAL);

  const throttledQueryFn = throttle(async () => {
    triggerDebouncedResetToStateOnStateChange = true;
    requestPendingCounter++;

    await queryFn();

    requestPendingCounter--;
  }, SLIDER_THROTTLE_INTERVAL);

  watch(stateValueToWatch, () => {
    if (requestPendingCounter) return;

    if (triggerDebouncedResetToStateOnStateChange) {
      debouncedResetToState();
    } else {
      resetToStateFn();
    }
  });

  return {
    debouncedResetToState,
    throttledQueryFn,
  };
};

export default useSliderValue;
