<template>
  <Text ref="root" tag="ul" class="tw-flex tw-flex-wrap tw-items-center tw-gap-x-1">
    <li>
      {{ $t('resources_usage.pid') }}: <strong class="tw-font-black">{{ app.pid }}</strong>
    </li>

    <li class="tw-w-0.5 tw-self-stretch tw-bg-purple/25"></li>

    <li>
      {{ $t('resources_usage.cpu') }}: <strong class="tw-font-black">{{ stats.cpu_usage }}%</strong>
    </li>

    <li class="tw-w-0.5 tw-self-stretch tw-bg-purple/25"></li>

    <li>
      {{ $t('resources_usage.gpu') }}: <strong class="tw-font-black">{{ stats.gpu_usage }}%</strong>
    </li>

    <li class="tw-w-0.5 tw-self-stretch tw-bg-purple/25"></li>

    <li>
      {{ $t('resources_usage.ram') }}: <strong class="tw-font-black">{{ stats.memory_usage }}%</strong>
    </li>

    <li class="tw-w-0.5 tw-self-stretch tw-bg-purple/25"></li>

    <li>
      {{ $t('resources_usage.disk') }}: <strong class="tw-font-black">{{ stats.disk_usage }}%</strong>
    </li>
  </Text>
</template>

<script setup>
import useDataStore from '@/stores/data';
import { Text } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { readonly, useTemplateRef } from 'vue';

const dataStore = useDataStore();
const { app, stats } = storeToRefs(dataStore);

const rootRef = useTemplateRef('root');

defineExpose({
  el: readonly(rootRef),
});
</script>
