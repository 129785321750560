<template>
  <Teleport to="#teleport-container">
    <Modal ref="modal" v-model="show" @opening="reset" @close="reset">
      <ModalContent size="large">
        <template #header>
          <Heading level="h2" level-class="h3">{{ $t('recorders_modal.title') }}</Heading>
        </template>

        <template #footer="{ reject }">
          <Button color="transparent" outlined @click="reject">{{ $t('cancel') }}</Button>
          <Button :disabled="locked" type="submit" form="recorders-form">{{ $t('save') }}</Button>
        </template>

        <Form id="recorders-form" class="tw-space-y-5" @submit="save">
          <div class="tw-grid tw-grid-cols-1 tw-gap-5 sm:tw-grid-cols-2">
            <Checkbox
              v-model="recorderIndicator"
              type="switch"
              :disabled="locked"
              :right-label="$t('recorders_modal.recorder_indicator.label')"
            />
          </div>

          <div class="tw-w-full tw-overflow-x-auto">
            <Table class="tw-min-w-160">
              <TableHeader>
                <TableRow class="tw-font-bold">
                  <TableCell>{{ $t('recorders_modal.device_type.title') }}</TableCell>
                  <TableCell>{{ $t('recorders_modal.recorder_source.title') }}</TableCell>
                  <TableCell>{{ $t('recorders_modal.recorder_name.title') }}</TableCell>
                  <TableCell class="tw-w-20">{{ $t('recorders_modal.actions.title') }}</TableCell>
                </TableRow>
              </TableHeader>

              <RecordersEditItem
                v-for="(recorder, index) in notDeletedRecorders"
                :key="recorder.recorder_id"
                ref="items"
                :recorder
                :index
                @delete="markDeleted"
              />

              <RecordersEditItem
                v-for="(recorder, index) in newRecorders"
                :key="recorder.recorder_id"
                ref="newItems"
                :recorder
                :index
                @delete="deleteNewRecorder"
              />
            </Table>
          </div>

          <Button :disabled="locked" color="transparentWhite" @click="addRecorder">
            <Icon name="plus" class="tw-mr-2 tw-size-6 tw-fill-current" />
            {{ $t('recorders_modal.add_recorder') }}
          </Button>
        </Form>
      </ModalContent>
    </Modal>
  </Teleport>
</template>

<script setup>
import api from '@/api';
import useDraftState from '@/composables/draft_state';
import useLoadingRequest from '@/composables/loading_request';
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import setIfChanged from '@/utils/set_if_changed';
import { generateRandomId } from '@slideslive/fuse-kit/utils';
import {
  Button,
  Checkbox,
  Form,
  Heading,
  Icon,
  Modal,
  ModalContent,
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { computed, ref, useTemplateRef } from 'vue';

import RecordersEditItem from './RecordersEditItem.vue';

const appStore = useAppStore();
const { locked } = storeToRefs(appStore);
const dataStore = useDataStore();
const { recorders, recordersConfig } = storeToRefs(dataStore);

const show = defineModel();
const modalRef = useTemplateRef('modal');
const itemRefs = useTemplateRef('items');
const newItemRefs = useTemplateRef('newItems');

const recorderIndicator = ref(recordersConfig.value.recorder_indicator_on);
const newRecorders = ref([]);
const deletedIds = ref([]);

const notDeletedRecorders = computed(() =>
  recorders.value.filter((recorder) => !deletedIds.value.includes(recorder.recorder_id)),
);
const updatedRecorders = computed(() => {
  let result = notDeletedRecorders.value;

  for (const itemRef of itemRefs.value) {
    const { id: recorderId, changed, ...itemRefData } = itemRef.getData();

    if (!changed) continue;

    if (!itemRef.isValid()) {
      result = result.filter((recorder) => recorderId !== recorder.recorder_id);
    }

    const recorderIndex = result.findIndex((recorder) => recorderId === recorder.recorder_id);

    result[recorderIndex] = {
      ...result[recorderIndex],
      ...itemRefData,
    };
  }

  return result;
});

const { trigger: patchState } = useDraftState(api.patchState, { statePathToClear: ['recorders_config'] });
const { trigger: patchRecorder } = useDraftState(api.patchRecorder, {
  statePath: ['recorders'],
  draftStateValue: updatedRecorders,
});
const { trigger: createRecorder } = useLoadingRequest(api.createRecorder);
const { trigger: deleteRecorder } = useDraftState(api.deleteRecorder, {
  withoutData: true,
  statePath: ['recorders'],
  draftStateValue: updatedRecorders,
});

const addRecorder = () => {
  if (locked.value) return;

  newRecorders.value = [
    ...newRecorders.value,
    {
      recorder_id: generateRandomId(20),
      recorder_name: '',
      device_type: recordersConfig.value.device_types[0],
      ip_address: '',
      removable: true,
    },
  ];
};

const markDeleted = (recorderId) => {
  if (locked.value) return;

  deletedIds.value.push(recorderId);
};

const deleteNewRecorder = (recorderId) => {
  if (locked.value) return;

  newRecorders.value = newRecorders.value.filter((recorder) => recorder.recorder_id !== recorderId);
};

const save = async () => {
  if (locked.value) return;

  const statePatch = { recorders_config: {} };

  setIfChanged(
    statePatch.recorders_config,
    'recorder_indicator_on',
    recordersConfig.value.recorder_indicator_on,
    recorderIndicator.value,
  );

  for (const itemRef of itemRefs.value) {
    const { id: recorderId, changed, ...itemRefData } = itemRef.getData();

    if (itemRef.isValid()) {
      if (changed) {
        patchRecorder(itemRefData, { key: 'recorder_id', recorderId });
      }
    } else {
      deleteRecorder({ key: 'recorder_id', recorderId });
    }
  }

  for (const recorderId of deletedIds.value) {
    deleteRecorder({ key: 'recorder_id', recorderId });
  }

  if (newItemRefs.value) {
    for (const newItemRef of newItemRefs.value) {
      if (!newItemRef.isValid()) continue;

      const { id, changed, ...newItemRefData } = newItemRef.getData();

      createRecorder(newItemRefData);
    }
  }

  if (Object.keys(statePatch.recorders_config).length) {
    patchState(statePatch);
  }

  modalRef.value.accept();
};

const reset = () => {
  recorderIndicator.value = recordersConfig.value.recorder_indicator_on;
  deletedIds.value = [];
  newRecorders.value = [];

  for (const itemRef of itemRefs.value) {
    itemRef.reset();
  }
};
</script>
