import { generateRandomId } from '@slideslive/fuse-kit/utils';
import { defineStore } from 'pinia';
import { computed, readonly, ref, unref } from 'vue';

const useAppStore = defineStore('notifications', () => {
  const notifications = ref([]);

  const visibleNotifications = computed(() => notifications.value.filter((notification) => !notification.closed));

  const addNotification = (value) => {
    const newNotification = {
      id: generateRandomId(20),
      title: null,
      closable: true,
      ...unref(value),
    };

    notifications.value.push(newNotification);

    return newNotification.id;
  };

  const closeNotification = (id) => {
    notifications.value = notifications.value.filter((notification) => notification.id !== id);
  };

  return {
    notifications: readonly(notifications),
    visibleNotifications: readonly(visibleNotifications),

    addNotification,
    closeNotification,
  };
});

export default useAppStore;
