import { isArray } from '@slideslive/fuse-kit/utils';

const getNestedObject = (value, path) => {
  if (!path) {
    return {
      pathArray: [],
      object: value,
      key: null,
    };
  }

  const pathArray = isArray(path) ? path : [path];
  let current = value;

  for (let i = 0; i < pathArray.length - 1; i++) {
    const key = pathArray[i];

    current[key] = current[key] || {};
    current = current[key];
  }

  const finalKey = pathArray[pathArray.length - 1];

  return {
    pathArray,
    object: current,
    key: finalKey,
  };
};

export default getNestedObject;
