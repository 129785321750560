import getNestedObject from '@/utils/get_nested_object';
import { deepEqual, deepMerge, isArray } from '@slideslive/fuse-kit/utils';
import { defineStore } from 'pinia';
import { computed, reactive, readonly, ref, unref } from 'vue';

const useDataStore = defineStore('data', () => {
  const sessionId = ref();
  const remoteStateUnresponsive = ref(true);
  const remoteStateDisabled = ref(false);

  const remoteState = reactive({});
  const draftState = reactive({});

  // do not use computed value for state for 2 reasons:
  // 1. update it only once all the changes to draftState/remoteState are finished
  // 2. do not create new object/array instance when kept the same to not trigger the change detection
  const state = reactive({});

  const isLoading = ref(true);
  const isFetching = ref(false);
  const isError = ref(false);
  const errors = ref([]);

  // loading indicator for requests that wait for the remote state update
  // two queues when request started after the polling request
  const loadingRequestsQueue = reactive({ current: new Set(), next: new Set() });
  const loadingRequests = computed(() => [
    ...Array.from(loadingRequestsQueue.current),
    ...Array.from(loadingRequestsQueue.next),
  ]);

  // draft state values to be cleared after the remote state update
  // two queues when the change happened after the polling request
  const clearFromDraftOnPollingQueue = reactive({ current: new Set(), next: new Set() });
  const clearFromDraftOnPolling = computed(() => [
    ...Array.from(clearFromDraftOnPollingQueue.current),
    ...Array.from(clearFromDraftOnPollingQueue.next),
  ]);

  const updateState = () => {
    Object.keys(remoteState).forEach((key) => {
      // do not mutate the remoteState - make a deep copy of it when merging with draftState
      const newEmptyKeyValue = isArray(remoteState[key]) ? [] : {};
      const newKeyValue =
        key in draftState
          ? deepMerge(deepMerge(newEmptyKeyValue, remoteState[key]), draftState[key], {
              replaceArrays: true,
            })
          : remoteState[key];

      // update the state only if the value has changed to handle the second reason for not using computed value
      if (!deepEqual(newKeyValue, state[key])) {
        state[key] = newKeyValue;
      }
    });
  };

  const updateDraftState = (path, value) => {
    if (!path) {
      deepMerge(draftState, value, { replaceArrays: true });
    } else {
      const { object, key } = getNestedObject(draftState, path);

      object[key] = value;
    }

    updateState();
  };

  const clearDraftState = (path) => {
    if (!path) {
      for (const key of Object.keys(draftState)) {
        delete draftState[key];
      }

      return;
    }

    const { pathArray, object, key } = getNestedObject(draftState, path);

    delete object[key];

    if (pathArray.length > 1 && Object.keys(object).length === 0) {
      clearDraftState(pathArray.slice(0, -1));
    }
  };

  const scheduleDraftClear = (value) => {
    if (isFetching.value) {
      clearFromDraftOnPollingQueue.next.add(value);
    } else {
      clearFromDraftOnPollingQueue.current.add(value);
    }
  };

  const removeFromScheduledDraftClear = (value) => {
    const valueAsString = value.join('.');

    for (const queueItem of Array.from(clearFromDraftOnPollingQueue.current)) {
      if (queueItem.join('.') !== valueAsString) continue;

      clearFromDraftOnPollingQueue.current.delete(queueItem);
      break;
    }

    for (const queueItem of Array.from(clearFromDraftOnPollingQueue.next)) {
      if (queueItem.join('.') !== valueAsString) continue;

      clearFromDraftOnPollingQueue.next.delete(queueItem);
      break;
    }
  };

  const updateRemoteState = (newData) => {
    newData = unref(newData);

    if (!newData) {
      for (const key of Object.keys(remoteState)) {
        delete remoteState[key];
      }

      return;
    }

    const { data, success, errors: newErrors } = newData;

    isError.value = !!newErrors?.length;
    errors.value = newErrors || [];

    if (data.close_session) {
      remoteStateDisabled.value = true;

      return;
    }

    if (!success) {
      remoteStateUnresponsive.value = true;

      return;
    }

    remoteStateUnresponsive.value = false;

    Object.assign(remoteState, data);

    for (const path of Array.from(clearFromDraftOnPollingQueue.current)) {
      clearDraftState(path);
    }

    clearFromDraftOnPollingQueue.current = clearFromDraftOnPollingQueue.next;
    clearFromDraftOnPollingQueue.next = new Set();

    updateState();

    loadingRequestsQueue.current = loadingRequestsQueue.next;
    loadingRequestsQueue.next = new Set();
  };

  const setLoadingRequest = (value) => {
    if (isFetching.value) {
      loadingRequestsQueue.next.add(value);
    } else {
      loadingRequestsQueue.current.add(value);
    }
  };

  const setSessionId = (value) => (sessionId.value = unref(value));
  const setIsLoading = (value) => (isLoading.value = unref(value));
  const setIsFetching = (value) => (isFetching.value = unref(value));
  const setIsError = (value) => (isError.value = unref(value));
  const setError = (value) => (errors.value = isArray(unref(value)) ? unref(value) : [unref(value)]);

  return {
    sessionId: readonly(sessionId),
    remoteStateUnresponsive: readonly(remoteStateUnresponsive),
    remoteStateDisabled: readonly(remoteStateDisabled),

    loadingRequests: readonly(loadingRequests),
    clearFromDraftOnPolling: readonly(clearFromDraftOnPolling),

    isLoading: readonly(isLoading),
    isFetching: readonly(isFetching),
    isError: readonly(isError),
    errors: readonly(errors),

    // desctructured state
    app: readonly(computed(() => state.app)),
    composition: readonly(computed(() => state.composition)),
    controller: readonly(computed(() => state.controller)),
    focus: readonly(computed(() => state.focus)),
    cameras: readonly(computed(() => state.cameras)),
    movement: readonly(computed(() => state.movement)),
    router: readonly(computed(() => state.router)),
    stats: readonly(computed(() => state.stats)),
    switcher: readonly(computed(() => state.switcher)),
    recordersConfig: readonly(computed(() => state.recorders_config)),
    presets: readonly(computed(() => state.presets)),
    recorders: readonly(computed(() => state.recorders)),
    speakers: readonly(computed(() => state.speakers)),
    zones: readonly(computed(() => state.zones)),

    setSessionId,
    setLoadingRequest,

    setIsLoading,
    setIsFetching,
    setIsError,
    setError,

    updateRemoteState,
    updateDraftState,
    clearDraftState,
    scheduleDraftClear,
    removeFromScheduledDraftClear,
  };
});

export default useDataStore;
