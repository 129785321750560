<template>
  <ControlSection>
    <FormTag
      v-model="model"
      :disabled="locked"
      type="radio"
      name="controlAction"
      value="push_focus"
      :label="$t('control_modes.focus.push_focus')"
      class="tw-whitespace-nowrap"
    />

    <div class="tw-flex tw-flex-wrap tw-items-center tw-gap-5">
      <Radio
        v-for="mode in focusModes"
        :key="mode.value"
        v-model="focusMode"
        name="focusMode"
        :disabled="locked"
        :id-prefix="mode.value"
        :value="mode.value"
        :right-label="t(`control_modes.focus.${mode.value.toLowerCase()}`)"
        @update:model-value="setFocusMode({ mode: $event })"
      />

      <div v-if="focusMode === 'MF'" class="tw-flex tw-items-center tw-gap-x-2">
        <Button ref="near" :disabled="locked" color="semiTransparentWhite">
          {{ $t('control_modes.focus.near') }}
        </Button>

        <Button ref="far" :disabled="locked" color="semiTransparentWhite">
          {{ $t('control_modes.focus.far') }}
        </Button>
      </div>
    </div>
  </ControlSection>
</template>

<script setup>
import api from '@/api';
import useDraftState from '@/composables/draft_state';
import useLoadingRequest from '@/composables/loading_request';
import { FOCUS_STEP } from '@/constants';
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import { Button, FormTag, Radio, useHoldButton } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { ref, useTemplateRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import ControlSection from './ControlSection.vue';

defineProps({
  controlMode: {
    type: String,
    required: true,
  },
});

const { t } = useI18n();
const appStore = useAppStore();
const { setPreviewActionDisabledReason, removePreviewActionDisabledReason } = appStore;
const { locked } = storeToRefs(appStore);
const dataStore = useDataStore();
const { focus } = storeToRefs(dataStore);

const model = defineModel();
const nearRef = useTemplateRef('near');
const farRef = useTemplateRef('far');

const focusModes = [
  {
    value: 'AF',
    label: t('control_modes.focus.af'),
  },
  {
    value: 'NF',
    label: t('control_modes.focus.nf'),
  },
  {
    value: 'MF',
    label: t('control_modes.focus.mf'),
  },
];

const focusMode = ref(focus.value.focus_mode);

const { loading: focusModeLoading, trigger: setFocusMode } = useDraftState(api.setFocusMode, {
  statePath: ['focus', 'focus_mode'],
  draftStateValue: focusMode,
});

const { trigger: setFocusNear } = useLoadingRequest(api.setFocusNear);
const { trigger: setFocusFar } = useLoadingRequest(api.setFocusFar);

useHoldButton(nearRef, () => setFocusNear({ step: FOCUS_STEP }));
useHoldButton(farRef, () => setFocusFar({ step: FOCUS_STEP }));

watch(
  () => focus.value.focus_mode,
  (value) => {
    focusMode.value = value;
  },
);

watch(focusModeLoading, (newModeLoading) => {
  if (newModeLoading) {
    setPreviewActionDisabledReason('focusMode');
  } else {
    removePreviewActionDisabledReason('focusMode');
  }
});
</script>
