import useDataStore from '@/stores/data';
import useNotificationsStore from '@/stores/notifications';
import handleError from '@/utils/handle_request_error';
import handleResponse from '@/utils/handle_request_response';
import { generateRandomId, isArray } from '@slideslive/fuse-kit/utils';
import { storeToRefs } from 'pinia';
import { computed, readonly, ref, unref } from 'vue';

/**
 * Composable options
 * @typedef {Object} Options
 * @property {boolean} [n=false] disabled - If the fetching is disabled.
 * @property {string[]} [n=null] statePath - Used to get to the nested value inside the draft state (and mock data).
 * @property {string[]} [n=null] statePathToClear - Used to clear the nested value inside the draft state (and mock
 * data) if different from statePath (usually when not patching whole state).
 * @property {*} [n=null] draftStateValue - Sets draft state value if different from what
 * is passed to request, eg. during delete or post without body.
 * @property {boolean} [n=false] withoutData - Used to trigger the query without passing any data, eg. during delete
 * single item or post without body.
 * @property {boolean} [n=false] lazy - If the draft value is set after the request finishes or before.
 * @property {boolean} [n=false] doNotAbortWhenLoading - If previous same request should not be aborted.
 */

/**
 * Composable used for api calls
 * When value should be stored in the draftStore to be visible sooner than after next state polling.
 *
 * @param {Function} queryFn - Function that is triggered to make the request.
 * @param {Options=} options - Composable options
 * @return {{ loading, trigger }} - Reactive loading state and trigger function.
 */
function useDraftState(
  queryFn,
  {
    disabled = false,
    statePath = null,
    statePathToClear = null,
    draftStateValue = null,
    withoutData = false,
    lazy = false,
    doNotAbortWhenLoading = false,
  } = {},
) {
  const dataStore = useDataStore();
  const { updateDraftState, clearDraftState, scheduleDraftClear, removeFromScheduledDraftClear } = dataStore;
  const { clearFromDraftOnPolling } = storeToRefs(dataStore);
  const notificationsStore = useNotificationsStore();
  const { closeNotification } = notificationsStore;

  const queryId = generateRandomId(20);
  const statePathAsString = (statePathToClear || statePath)?.join?.('.');
  const fetching = ref(false);
  const loading = computed(
    () =>
      fetching.value ||
      clearFromDraftOnPolling.value.some((k) => {
        const kAsString = isArray(k) ? k.join('.') : k;

        return kAsString === statePathAsString;
      }),
  );
  let lastRequestId = null;
  let abortController = new AbortController();

  /**
   * Function to trigger the queryFn
   *
   * @param {*} data - Data to be sent to the server and optionaly draftState.
   * @param {{ key?, [key]? }} options - Options to be passed to the queryFn, usually contain 'key' and '[key]' for
   * replacing item ID ({ key: 'speaker_id', speakerId: 123 }).
   */
  const trigger = async (...args) => {
    if (!unref(queryFn) || unref(disabled)) return;

    if (unref(fetching) && !unref(doNotAbortWhenLoading)) {
      abortController.abort();
    }

    if (abortController.signal.aborted) {
      abortController = new AbortController();
    }

    fetching.value = true;

    const requestId = generateRandomId(20);
    let data;
    let params;

    if (withoutData) {
      [params] = args;
    } else {
      [data, params] = args;
    }

    lastRequestId = requestId;

    const doUpdateDraftState = () => {
      // remove the path from the scheduled draft clear queue to prevent it from being cleared while the request is in
      removeFromScheduledDraftClear(statePathToClear || statePath);
      updateDraftState(statePath, unref(draftStateValue) ?? data);
    };

    if (!lazy) {
      doUpdateDraftState();
    }

    try {
      let response;

      closeNotification(queryId);

      // statePath and draftStateValue are passed to queryFn only because of MockDataFetcher to persist the changes
      if (withoutData) {
        response = await unref(queryFn)(null, { ...params, abortController, statePath, draftStateValue });
      } else {
        response = await unref(queryFn)(data, { ...params, abortController, statePath, draftStateValue });
      }

      if (isArray(response)) {
        response = response.filter((r) => r);
      }

      if (!response || (isArray(response) && !response.length)) {
        if (lastRequestId === requestId) {
          scheduleDraftClear(statePathToClear || statePath);
        }

        return;
      }

      const success = handleResponse(response);

      if (success) {
        if (lazy) {
          doUpdateDraftState();
        }
      }

      if (lastRequestId === requestId) {
        scheduleDraftClear(statePathToClear || statePath);
      }
    } catch (error) {
      clearDraftState(statePathToClear || statePath);
      handleError(error, params, data, queryId);
    } finally {
      if (lastRequestId === requestId) {
        fetching.value = false;
      }
    }
  };

  return {
    loading: readonly(loading),
    trigger,
  };
}

export default useDraftState;
