<template>
  <TabPanel class="tw-grid tw-grid-cols-1 tw-gap-x-10 tw-gap-y-3 sm:tw-grid-cols-2">
    <SettingsRange
      v-tooltip="safeZoneSizePanTooltip"
      v-model.number="safeZoneSizePan"
      :disabled="locked"
      :min="movement.safe_zone_size_pan.min"
      :max="movement.safe_zone_size_pan.max"
      :step="movement.safe_zone_size_pan.step"
      :label="$t('settings_tabs.main.safe_zone_pan.label')"
      @update:model-value="throttledSafeZoneSizePanPatch"
    />

    <SettingsRange
      v-tooltip="shotScaleTooltip"
      v-model.number="shotScale"
      :disabled="locked"
      :min="composition.shot_scale.min"
      :max="composition.shot_scale.max"
      :step="composition.shot_scale.step"
      :label="$t('settings_tabs.main.shot_scale.label')"
      @update:model-value="throttledShotScalePatch"
    />

    <SettingsRange
      v-tooltip="safeZoneSizeTiltTooltip"
      v-model.number="safeZoneSizeTilt"
      :disabled="locked"
      :min="movement.safe_zone_size_tilt.min"
      :max="movement.safe_zone_size_tilt.max"
      :step="movement.safe_zone_size_tilt.step"
      :label="$t('settings_tabs.main.safe_zone_tilt.label')"
      @update:model-value="throttledSafeZoneSizeTiltPatch"
    />

    <SettingsRange
      v-tooltip="leadRoomFactorTooltip"
      v-model.number="leadRoomFactor"
      :disabled="locked"
      :min="composition.lead_room_factor.min"
      :max="composition.lead_room_factor.max"
      :step="composition.lead_room_factor.step"
      :label="$t('settings_tabs.main.lead_room_factor.label')"
      @update:model-value="throttledLeadRoomFactorPatch"
    />

    <SettingsRange
      v-tooltip="safeZoneSizeZoomTooltip"
      v-model.number="safeZoneSizeZoom"
      :disabled="locked"
      :min="movement.safe_zone_size_zoom.min"
      :max="movement.safe_zone_size_zoom.max"
      :step="movement.safe_zone_size_zoom.step"
      :label="$t('settings_tabs.main.safe_zone_zoom.label')"
      @update:model-value="throttledSafeZoneSizeZoomPatch"
    />
  </TabPanel>
</template>

<script setup>
import api from '@/api';
import useLoadingRequest from '@/composables/loading_request';
import useSliderValue from '@/composables/slider_value';
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import { TabPanel } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import SettingsRange from './SettingsRange.vue';

const { t } = useI18n();
const appStore = useAppStore();
const { locked, tooltips } = storeToRefs(appStore);
const dataStore = useDataStore();
const { movement, composition } = storeToRefs(dataStore);

const safeZoneSizePan = ref(movement.value.safe_zone_size_pan.value);
const safeZoneSizeTilt = ref(movement.value.safe_zone_size_tilt.value);
const safeZoneSizeZoom = ref(movement.value.safe_zone_size_zoom.value);
const shotScale = ref(composition.value.shot_scale.value);
const leadRoomFactor = ref(composition.value.lead_room_factor.value);

const safeZoneSizePanTooltip = computed(() => ({
  disabled: !tooltips.value,
  content: t('settings_tabs.main.safe_zone_pan.tooltip', { value: safeZoneSizePan.value }),
}));

const safeZoneSizeTiltTooltip = computed(() => ({
  disabled: !tooltips.value,
  content: t('settings_tabs.main.safe_zone_tilt.tooltip', { value: safeZoneSizeTilt.value }),
}));

const safeZoneSizeZoomTooltip = computed(() => ({
  disabled: !tooltips.value,
  content: t('settings_tabs.main.safe_zone_zoom.tooltip', { value: safeZoneSizeZoom.value }),
}));

const shotScaleTooltip = computed(() => ({
  disabled: !tooltips.value,
  content: t('settings_tabs.main.shot_scale.tooltip', { value: shotScale.value }),
}));

const leadRoomFactorTooltip = computed(() => ({
  disabled: !tooltips.value,
  content: t('settings_tabs.main.lead_room_factor.tooltip', { value: leadRoomFactor.value }),
}));

const { trigger: patchState } = useLoadingRequest(api.patchState);

const { throttledQueryFn: throttledSafeZoneSizePanPatch } = useSliderValue({
  queryFn: () => patchState({ movement: { safe_zone_size_pan: { value: safeZoneSizePan.value } } }),
  resetToStateFn: () => {
    safeZoneSizePan.value = movement.value.safe_zone_size_pan.value;
  },
  stateValueToWatch: () => movement.value.safe_zone_size_pan.value,
});

const { throttledQueryFn: throttledSafeZoneSizeTiltPatch } = useSliderValue({
  queryFn: () => patchState({ movement: { safe_zone_size_tilt: { value: safeZoneSizeTilt.value } } }),
  resetToStateFn: () => {
    safeZoneSizeTilt.value = movement.value.safe_zone_size_tilt.value;
  },
  stateValueToWatch: () => movement.value.safe_zone_size_tilt.value,
});

const { throttledQueryFn: throttledSafeZoneSizeZoomPatch } = useSliderValue({
  queryFn: () => patchState({ movement: { safe_zone_size_zoom: { value: safeZoneSizeZoom.value } } }),
  resetToStateFn: () => {
    safeZoneSizeZoom.value = movement.value.safe_zone_size_zoom.value;
  },
  stateValueToWatch: () => movement.value.safe_zone_size_zoom.value,
});

const { throttledQueryFn: throttledShotScalePatch } = useSliderValue({
  queryFn: () => patchState({ composition: { shot_scale: { value: shotScale.value } } }),
  resetToStateFn: () => {
    shotScale.value = composition.value.shot_scale.value;
  },
  stateValueToWatch: () => composition.value.shot_scale.value,
});

const { throttledQueryFn: throttledLeadRoomFactorPatch } = useSliderValue({
  queryFn: () => patchState({ composition: { lead_room_factor: { value: leadRoomFactor.value } } }),
  resetToStateFn: () => {
    leadRoomFactor.value = composition.value.lead_room_factor.value;
  },
  stateValueToWatch: () => composition.value.lead_room_factor.value,
});
</script>
