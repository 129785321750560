<template>
  <TabPanel class="tw-grid tw-grid-cols-1 tw-gap-x-10 tw-gap-y-5 sm:tw-grid-cols-2">
    <SettingsRange
      v-tooltip="audioInputVolumeTooltip"
      v-model.number="audioInputVolume"
      :disabled="locked"
      :min="cameras.main.audio_input_volume.min"
      :max="cameras.main.audio_input_volume.max"
      :step="cameras.main.audio_input_volume.step"
      :label="$t('settings_tabs.audio.audio_input_volume.label')"
      @update:model-value="throttlePatchMainCamera"
    />

    <FormField
      :model-value="cameras.main.audio_input_mode.selected_index"
      type="select"
      :disabled="locked"
      :label="$t('settings_tabs.audio.audio_input_mode.label')"
      @update:model-value="patchMainCamera({ input_mode: Number($event) })"
    >
      <option v-for="(option, index) in cameras.main.audio_input_mode.options" :key="option" :value="index">
        {{ option }}
      </option>
    </FormField>

    <div class="tw-flex tw-gap-5">
      <FormField
        v-model="audioMonitorMix"
        type="select"
        :disabled="audioStream ? audioLoading : false"
        :label="$t('settings_tabs.audio.audio_monitor_mix.label')"
        class="tw-flex-1"
      >
        <option v-for="{ value, label } in audioMonitorMixOptions" :key="value" :value="value">{{ label }}</option>
      </FormField>

      <CircularButton
        :icon="audioStreamIcon"
        color="transparentWhite"
        size="large"
        shape="rounded"
        :loading="audioLoading"
        @click="toggleAudioStream"
      >
        <audio ref="audio" :src="audioStream ? audioSrc : null" preload="none" />
      </CircularButton>
    </div>
  </TabPanel>
</template>

<script setup>
import api from '@/api';
import useLoadingRequest from '@/composables/loading_request';
import useSliderValue from '@/composables/slider_value';
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import useNotificationsStore from '@/stores/notifications';
import buildNateUrl from '@/utils/build_nate_url';
import { CircularButton, FormField, TabPanel } from '@slideslive/fuse-kit/vue';
import { useEventListener } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed, onMounted, onUnmounted, ref, useTemplateRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import SettingsRange from './SettingsRange.vue';

const { t } = useI18n();
const appStore = useAppStore();
const { locked, tooltips } = storeToRefs(appStore);
const dataStore = useDataStore();
const { cameras } = storeToRefs(dataStore);
const notificationsStore = useNotificationsStore();
const { addNotification, closeNotification } = notificationsStore;

const audioMonitorMixOptions = [
  {
    value: 'LR',
    label: 'L + R',
  },
  {
    value: 'LL',
    label: 'L + L',
  },
  {
    value: 'RR',
    label: 'R + R',
  },
  {
    value: 'mono',
    label: 'mono',
  },
];

const audioInputVolume = ref(cameras.value.main.audio_input_volume.value);
const audioMonitorMix = ref(audioMonitorMixOptions[0].value);
const audioStream = ref(false);
const audioSrc = ref(null);
const audioLoading = ref(false);

const audioRef = useTemplateRef('audio');

const audioInputVolumeTooltip = computed(() => ({
  disabled: !tooltips.value,
  content: t('settings_tabs.audio.audio_input_volume.tooltip'),
}));
const audioStreamIcon = computed(() => (audioStream.value ? 'volume-up' : 'volume-off'));

const { trigger: patchMainCamera } = useLoadingRequest(api.patchMainCamera);

const playAudioStream = async () => {
  if (audioLoading.value) return;

  const cleanup = watch(audioLoading, async () => {
    if (audioLoading.value) return;

    try {
      await audioRef.value.play();
    } catch (error) {
      audioRef.value.pause();

      addNotification({
        id: 'audioStream',
        type: 'error',
        title: 'Error playing audio stream',
        message: error.message,
      });

      audioStream.value = false;
    } finally {
      cleanup();
    }
  });

  audioRef.value.load();
};

const pauseAudioStream = () => {
  audioRef.value.pause();
};

const toggleAudioStream = async () => {
  audioStream.value = !audioStream.value;

  closeNotification('audioStream');

  if (audioStream.value) {
    await playAudioStream();
  } else {
    pauseAudioStream();
  }
};

const updateAudioSrc = () => {
  audioRef.value.src = buildNateUrl(`/streams/audio?mix=${audioMonitorMix.value}`);
};

const { throttledQueryFn: throttlePatchMainCamera } = useSliderValue({
  queryFn: () => patchMainCamera({ input_volume: audioInputVolume.value }),
  resetToStateFn: () => {
    audioInputVolume.value = cameras.value.main.audio_input_volume.value;
  },
  stateValueToWatch: () => cameras.value.main.audio_input_volume.value,
});

useEventListener(audioRef, 'loadstart', () => {
  if (!audioStream.value) return;

  audioLoading.value = true;
});

useEventListener(audioRef, 'loadeddata', () => {
  audioLoading.value = false;
});

useEventListener(audioRef, 'error', () => {
  audioLoading.value = false;
});

watch(audioMonitorMix, async () => {
  closeNotification('audioStream');

  if (audioStream.value) {
    pauseAudioStream();
  }

  updateAudioSrc();

  if (audioStream.value) {
    await playAudioStream();
  }
});

onMounted(() => {
  updateAudioSrc();
});

onUnmounted(() => {
  if (audioRef.value) {
    audioRef.value.pause();
    audioRef.value.src = '';
  }
});
</script>
