import useNotificationsStore from '@/stores/notifications';

const handleRequestError = (error, params, data, queryId) => {
  if (error.name === 'AbortError') return;

  const notificationsStore = useNotificationsStore();
  const { addNotification, closeNotification } = notificationsStore;

  console.error(error);

  const detail = {
    params,
    payload: data,
  };

  closeNotification(queryId);
  addNotification({
    id: queryId,
    type: 'error',
    message: error.message,
    detail,
  });
};

export default handleRequestError;
