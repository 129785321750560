import useDataStore from '@/stores/data';
import { defineStore, storeToRefs } from 'pinia';
import { computed, readonly, ref, unref } from 'vue';

const useAppStore = defineStore('app', () => {
  const dataStore = useDataStore();
  const { controller } = storeToRefs(dataStore);

  const locked = ref(false);
  const disabledPreviewAndMultiview = ref(false);
  const viewZoom = ref(1);

  // control action per control mode
  const watcherControlAction = ref('move_pan_tilt');
  const trackerControlAction = ref('add_speaker');

  // settings
  const tooltips = ref(true);
  const resourcesUsage = ref(false);
  const activeSpeakersTimeWindow = ref(3600);

  const controlMode = computed(() => controller.value?.control_mode);
  const controlAction = computed({
    get() {
      if (controlMode.value === 'watcher') return watcherControlAction.value;
      if (controlMode.value === 'tracker') return trackerControlAction.value;

      return null;
    },
    set(value) {
      if (controlMode.value === 'watcher') {
        watcherControlAction.value = value;
      } else if (controlMode.value === 'tracker') {
        trackerControlAction.value = value;
      }
    },
  });

  // preview clicking should be disabled when waiting for requests that change the behavior
  // can be different reasons, do not use simple boolean
  const previewActionDisabledReasons = ref([]);
  const previewActionDisabled = computed(() => {
    if (previewActionDisabledReasons.value.includes('controlMode')) return true;
    if (controlAction.value === 'push_focus' && previewActionDisabledReasons.value.includes('focusMode')) return true;
    if (controlAction.value === 'draw_tz' && previewActionDisabledReasons.value.includes('trackingZone')) return true;

    return false;
  });

  const setLocked = (value) => (locked.value = unref(value));
  const setDisabledPreviewAndMultiview = (value) => (disabledPreviewAndMultiview.value = unref(value));
  const setViewZoom = (value) => (viewZoom.value = unref(value));
  const setTooltips = (value) => (tooltips.value = unref(value));
  const setResourcesUsage = (value) => (resourcesUsage.value = unref(value));
  const setActiveSpeakersTimeWindow = (value) => (activeSpeakersTimeWindow.value = unref(value));
  const setControlAction = (value) => (controlAction.value = unref(value));

  const setPreviewActionDisabledReason = (value) => {
    const unrefValue = unref(value);

    if (!previewActionDisabledReasons.value.includes(unrefValue)) {
      previewActionDisabledReasons.value.push(unrefValue);
    }
  };

  const removePreviewActionDisabledReason = (value) =>
    (previewActionDisabledReasons.value = previewActionDisabledReasons.value.filter(
      (reason) => reason !== unref(value),
    ));

  return {
    locked: readonly(locked),
    disabledPreviewAndMultiview: readonly(disabledPreviewAndMultiview),
    viewZoom: readonly(viewZoom),
    tooltips: readonly(tooltips),
    resourcesUsage: readonly(resourcesUsage),
    activeSpeakersTimeWindow: readonly(activeSpeakersTimeWindow),

    controlAction: readonly(controlAction),
    previewActionDisabled: readonly(previewActionDisabled),

    setLocked,
    setDisabledPreviewAndMultiview,
    setViewZoom,
    setControlAction,
    setTooltips,
    setResourcesUsage,
    setActiveSpeakersTimeWindow,
    setPreviewActionDisabledReason,
    removePreviewActionDisabledReason,
  };
});

export default useAppStore;
