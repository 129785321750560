export const DEFAULT_POLLING_INTERVAL = 1000;
export const DEFAULT_REFETCH_ON_WINDOW_FOCUS = true;
export const DEFAULT_RETRY_COUNT = 5;

export const CAMERA_MOVE_STEP = 0.05;
export const CAMERA_ZOOM_STEP = 0.05;
export const COMPOSITION_MOVE_STEP = 0.05;
export const FOCUS_STEP = 0.05;
export const TRACKING_ZONE_STEP = 0.01;

export const SLIDER_THROTTLE_INTERVAL = 100;
export const SLIDER_RESET_TO_STATE_DEBOUNCE_INTERVAL = 2000;

// Threshold below 1 is in percentage, above and equal to 1 is in pixels
export const MOVE_WIDTH_THRESHOLD = 0.01;
export const MOVE_HEIGHT_THRESHOLD = 0.01;
export const TRACKING_ZONE_WIDTH_THRESHOLD = 0.01;
export const TRACKING_ZONE_HEIGHT_THRESHOLD = 0.01;

// Do not change, only for development purposes
export const DEV_NATE_HOST = '10.210.20.2:5003';
