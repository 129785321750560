import { isObject } from '@slideslive/fuse-kit/utils';
import { unref } from 'vue';

const setIfChanged = (target, key, sourceValue, newValue) => {
  const unrefSourceValue = unref(sourceValue);
  const unrefNewValue = unref(newValue);
  const newValueToCompare = isObject(unrefNewValue) && 'value' in unrefNewValue ? unrefNewValue.value : unrefNewValue;

  if (unrefSourceValue !== newValueToCompare) {
    target[key] = unrefNewValue;
  }
};

export default setIfChanged;
