<template>
  <div ref="root" class="tw-flex tw-flex-col">
    <div class="tw-flex tw-items-start tw-justify-between tw-gap-2">
      <ValueSwitcher
        v-model="controlMode"
        :disabled="locked"
        :options="controleModeOptions"
        :body-attrs="{ class: 'tw-flex-nowrap' }"
        @update:model-value="setControlMode"
      />

      <div class="tw-flex tw-flex-wrap tw-justify-end tw-gap-2">
        <Button
          v-if="controlMode === 'watcher'"
          :loading="setAsHomeViewLoading"
          :disabled="locked"
          color="transparent"
          outlined
          @click="setAsHomeView"
        >
          {{ $t('control_modes.set_as_home') }}
        </Button>

        <Button :loading="resetToHomeViewLoading" :disabled="locked" @click="resetToHomeView">
          {{ $t('control_modes.reset_to_home') }}
        </Button>
      </div>
    </div>

    <div
      ref="scroll"
      :class="[
        'tw-relative tw-z-0 tw--mx-2 tw--my-4 tw-mt-1 tw-p-4 md:tw--mx-4 xl:tw-overflow-x-hidden',
        controlModeLoading ? 'xl:tw-overflow-y-hidden' : 'xl:tw-overflow-y-auto',
      ]"
    >
      <div
        v-if="controlModeLoading"
        class="tw-absolute tw-inset-0 tw-z-10 tw-flex tw-items-center tw-justify-center tw-backdrop-blur"
      >
        <Spinner size="large" />
      </div>

      <div class="tw-relative tw-z-0 tw-flex tw-flex-col tw-space-y-2 tw-pb-10 sm:tw-pb-0">
        <Logo class="tw-absolute tw-bottom-2 tw-right-0 tw-w-28" />

        <CameraMove v-if="controlMode === 'watcher'" v-model="controlAction" />
        <Composition v-if="controlMode === 'tracker'" />
        <TrackingZone v-model="controlAction" />
        <Focus v-model="controlAction" :control-mode="controlMode" />
        <Speakers v-model="controlAction" :control-mode="controlMode" />
        <CameraSelect />
      </div>
    </div>
  </div>
</template>

<script setup>
import api from '@/api';
import useDraftState from '@/composables/draft_state';
import useLoadingUntilRequested from '@/composables/loading_until_requested';
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import { Button, Logo, Spinner, ValueSwitcher, useModelStoreWrapper } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { computed, readonly, ref, useTemplateRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import CameraMove from './CameraMove.vue';
import CameraSelect from './CameraSelect.vue';
import Composition from './Composition.vue';
import Focus from './Focus.vue';
import Speakers from './Speakers.vue';
import TrackingZone from './TrackingZone.vue';

const { t } = useI18n();
const appStore = useAppStore();
const { setPreviewActionDisabledReason, removePreviewActionDisabledReason } = appStore;
const { locked } = storeToRefs(appStore);
const dataStore = useDataStore();
const { controller } = storeToRefs(dataStore);

const rootRef = useTemplateRef('root');
const scrollRef = useTemplateRef('scroll');

const controlAction = useModelStoreWrapper(appStore, 'controlAction');
const { loading: setAsHomeViewLoading, trigger: setAsHomeView } = useLoadingUntilRequested(api.setAsHomeView, {
  withoutData: true,
});
const { loading: resetToHomeViewLoading, trigger: resetToHomeView } = useLoadingUntilRequested(api.resetToHomeView, {
  withoutData: true,
});

const controleModeOptions = [
  {
    value: 'watcher',
    label: t('control_modes.watcher'),
  },
  {
    value: 'tracker',
    label: t('control_modes.tracker'),
  },
];

const controlMode = ref(controller.value.control_mode);
const changeControlModeFn = computed(() => {
  if (controlMode.value === 'watcher') {
    return api.setWatcherMode;
  }

  if (controlMode.value === 'tracker') {
    return api.setTrackerMode;
  }

  return null;
});

const { loading: controlModeLoading, trigger: setControlMode } = useDraftState(changeControlModeFn, {
  withoutData: true,
  statePath: ['controller', 'control_mode'],
  draftStateValue: controlMode,
});

watch(controlModeLoading, (newLoading) => {
  if (newLoading) {
    scrollRef.value.scrollTop = 0;
    setPreviewActionDisabledReason('controlMode');
  } else {
    removePreviewActionDisabledReason('controlMode');
  }
});

watch(
  () => controller.value.control_mode,
  (value) => {
    if (controlMode.value !== value) {
      controlMode.value = value;
    }
  },
);

defineExpose({
  el: readonly(rootRef),
});
</script>
