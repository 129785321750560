<template>
  <ControlSection>
    <FormTag
      v-model="model"
      :disabled="locked"
      type="radio"
      name="controlAction"
      value="draw_tz"
      :label="$t('control_modes.tracking_zones.draw')"
      class="tw-whitespace-nowrap"
    />

    <div v-if="zones.length" class="tw-flex tw-flex-wrap tw-items-center tw-gap-2">
      <Dropdown
        v-for="{ edge, label, actions } in trackingZonesBorders"
        :key="edge"
        :overlay="false"
        :disabled="locked || deleteTrackingZoneLoading"
        :trigger-attrs="{
          iconButton: true,
          reset: true,
          icon: `border-${edge}`,
          color: 'semiTransparentWhite',
          shape: 'rounded',
          size: 'large',
          ariaLabel: label,
        }"
        :menu-attrs="{ class: 'tw-min-w-0' }"
        :append-to="appendDropdownTo"
      >
        <template #menu>
          <TrackingZoneDropdownButton
            v-for="action in actions"
            :key="`${edge}-${action}`"
            :edge
            :action
            :disabled="locked || deleteTrackingZoneLoading"
          />
        </template>
      </Dropdown>

      <CircularButton
        icon="trash"
        color="semiTransparentWhite"
        shape="rounded"
        size="large"
        :loading="deleteTrackingZoneLoading"
        :disabled="locked"
        :aria-label="$t('control_modes.tracking_zones.delete')"
        @click="deleteTrackingZone({ key: 'zone_id', zoneId: 0 })"
      />
    </div>
  </ControlSection>
</template>

<script setup>
import api from '@/api';
import useLoadingRequest from '@/composables/loading_request';
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import { CircularButton, Dropdown, FormTag } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import ControlSection from './ControlSection.vue';
import TrackingZoneDropdownButton from './TrackingZoneDropdownButton.vue';

const { t } = useI18n();
const appStore = useAppStore();
const { setPreviewActionDisabledReason, removePreviewActionDisabledReason } = appStore;
const { locked, viewZoom } = storeToRefs(appStore);
const dataStore = useDataStore();
const { zones } = storeToRefs(dataStore);

const model = defineModel();

const appendDropdownTo = computed(() => (viewZoom <= 1 ? '#teleport-container' : undefined));

const { loading: deleteTrackingZoneLoading, trigger: deleteTrackingZone } = useLoadingRequest(api.deleteTrackingZone, {
  withoutData: true,
});

const trackingZonesBorders = [
  {
    edge: 'top',
    label: t('control_modes.tracking_zones.top'),
    actions: ['up', 'down'],
  },
  {
    edge: 'bottom',
    label: t('control_modes.tracking_zones.bottom'),
    actions: ['up', 'down'],
  },
  {
    edge: 'left',
    label: t('control_modes.tracking_zones.left'),
    actions: ['left', 'right'],
  },
  {
    edge: 'right',
    label: t('control_modes.tracking_zones.right'),
    actions: ['left', 'right'],
  },
];

watch(deleteTrackingZoneLoading, (newLoading) => {
  if (newLoading) {
    setPreviewActionDisabledReason('trackingZone');
  } else {
    removePreviewActionDisabledReason('trackingZone');
  }
});
</script>
