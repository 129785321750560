<template>
  <div v-bind="$attrs">
    <Button color="transparentWhite" class="tw-aspect-video tw-w-full tw-p-0" block @click="openMultiview">
      <img v-show="!disabledPreviewAndMultiview" :src="multiviewUrl" class="tw-size-full tw-object-cover" />
    </Button>
  </div>

  <MultiviewModal v-model="multiviewOpen" />
</template>

<script setup>
import useAppStore from '@/stores/app';
import buildNateUrl from '@/utils/build_nate_url';
import { Button } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';

import MultiviewModal from './MultiviewModal.vue';

const appStore = useAppStore();
const { disabledPreviewAndMultiview } = storeToRefs(appStore);

const multiviewOpen = ref(false);
const srcParamToReload = ref(0);

const multiviewUrl = computed(() =>
  disabledPreviewAndMultiview.value ? null : buildNateUrl(`/streams/multiview?width=416&q=60&fps=12&idx=220`),
);

const openMultiview = () => {
  multiviewOpen.value = true;
};

watch(disabledPreviewAndMultiview, (value) => {
  if (value) {
    srcParamToReload.value += 1;
  }
});
</script>
