<template>
  <div class="tw-space-y-5">
    <Heading level="h3" level-class="h4">{{ $t('settings_modal.personal.title') }}</Heading>

    <div class="tw-flex tw-flex-wrap tw-items-center tw-gap-5">
      <Checkbox
        v-model="tooltips"
        type="switch"
        :disabled="disabled"
        :right-label="$t('settings_modal.personal.tooltips.label')"
      />

      <Checkbox
        v-model="previewInfo"
        type="switch"
        :disabled="disabled || locked"
        :right-label="$t('settings_modal.personal.preview_info.label')"
      />

      <Checkbox
        v-model="resourcesUsage"
        type="switch"
        :disabled="disabled"
        :right-label="$t('settings_modal.personal.resources_usage.label')"
      />
    </div>
  </div>
</template>

<script setup>
import useAppStore from '@/stores/app';
import { Checkbox, Heading } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';

defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
});

const appStore = useAppStore();
const { locked } = storeToRefs(appStore);

const tooltips = defineModel('tooltips');
const previewInfo = defineModel('previewInfo');
const resourcesUsage = defineModel('resourcesUsage');
</script>
