<template>
  <Teleport to="#teleport-container">
    <Modal v-model="show">
      <ModalContent size="large">
        <template #header>
          <Heading level="h2" level-class="h3">{{ $t('changelog_modal.title') }}</Heading>
        </template>

        <template #footer="{ reject }">
          <Button color="transparent" outlined @click="reject">{{ $t('close') }}</Button>
        </template>

        <div v-if="error" class="tw-space-y-5">
          <Text>{{ $t('changelog_modal.error') }}: {{ error }}</Text>
          <Button color="semiTransparentWhite" @click="fetchChangelog">{{ $t('changelog_modal.try_again') }}</Button>
        </div>

        <Spinner v-else-if="loading || !markdownSource" size="large" class="tw-mx-auto" />

        <VueMarkdown v-else :source="markdownSource" class="fuse-fuse-prose" />
      </ModalContent>
    </Modal>
  </Teleport>
</template>

<script setup>
import api from '@/api';
import { Button, Heading, Modal, ModalContent, Spinner, Text } from '@slideslive/fuse-kit/vue';
import { ref, watch } from 'vue';
import VueMarkdown from 'vue-markdown-render';

const show = defineModel();

const loading = ref(true);
const error = ref(null);
const markdownSource = ref('');
let abortController;

const fetchChangelog = async () => {
  error.value = null;
  loading.value = true;

  try {
    abortController = new AbortController();

    const response = await api.changelog(null, { abortController, contentType: 'text/markdown' });

    markdownSource.value = response;
  } catch (err) {
    if (err.name === 'AbortError') return;

    console.error(err);
    error.value = err.message || err;
  } finally {
    loading.value = false;
  }
};

watch(show, () => {
  if (show.value) {
    if (markdownSource.value !== '') return;

    fetchChangelog();
  } else {
    abortController?.abort();
    abortController = null;
  }
});
</script>
