<template>
  <div class="tw-pointer-events-none tw-fixed tw-inset-y-0 tw-right-0 tw-z-[201] tw-w-104 tw-overflow-hidden">
    <TransitionGroup
      name="slide-left"
      tag="ol"
      class="tw-overflow-x-inherit tw-size-full tw-space-y-5 tw-overflow-y-auto tw-py-5 tw-pr-5"
    >
      <Notification
        v-for="notification in visibleNotifications"
        :key="notification.id"
        :notification
        @close="closeNotification"
      />
    </TransitionGroup>
  </div>
</template>

<script setup>
import useNotiticationsStore from '@/stores/notifications';
import { storeToRefs } from 'pinia';

import Notification from './Notification.vue';

const notificationsStore = useNotiticationsStore();
const { closeNotification } = notificationsStore;
const { visibleNotifications } = storeToRefs(notificationsStore);
</script>
