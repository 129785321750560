import useNotificationsStore from '@/stores/notifications';
import handleError from '@/utils/handle_request_error';
import handleResponse from '@/utils/handle_request_response';
import { generateRandomId, isArray } from '@slideslive/fuse-kit/utils';
import { readonly, ref, unref } from 'vue';

/**
 * Composable options
 * @typedef {Object} Options
 * @property {boolean} [n=false] disabled - If the fetching is disabled.
 * @property {boolean} [n=false] withoutData - Used to trigger the query without passing any data, eg. during delete
 * single item or post without body.
 * @property {boolean} [n=false] doNotAbortWhenLoading - If previous same request should not be aborted.
 */

/**
 * Composable used for api calls
 * To get loading state and abort functionality, loading false once the query is finished.
 *
 * @param {Function} queryFn - Function that is triggered to make the request.
 * @param {Options=} options - Composable options
 * @return {{ loading, trigger }} - Reactive loading state and trigger function.
 */
function useLoadingRequest(queryFn, { disabled = false, withoutData = false, doNotAbortWhenLoading = false } = {}) {
  const notificationsStore = useNotificationsStore();
  const { closeNotification } = notificationsStore;

  const queryId = generateRandomId(20);
  const loading = ref(false);
  let lastRequestId = null;
  let abortController = new AbortController();

  /**
   * Function to trigger the queryFn
   *
   * @param {*} data - Data to be sent to the server and optionaly draftState.
   * @param {{ key?, [key]? }} options - Options to be passed to the queryFn, usually contain 'key' and '[key]' for
   * replacing item ID ({ key: 'speaker_id', speakerId: 123 }).
   */
  const trigger = async (...args) => {
    if (unref(disabled)) return;

    if (unref(loading) && !unref(doNotAbortWhenLoading)) {
      abortController.abort();
    }

    if (abortController.signal.aborted) {
      abortController = new AbortController();
    }

    loading.value = true;

    const requestId = generateRandomId(20);
    let data;
    let params;

    if (withoutData) {
      [params] = args;
    } else {
      [data, params] = args;
    }

    lastRequestId = requestId;

    try {
      let response;

      closeNotification(queryId);

      if (withoutData) {
        response = await unref(queryFn)(null, { ...params, abortController });
      } else {
        response = await unref(queryFn)(data, { ...params, abortController });
      }

      if (isArray(response)) {
        response = response.filter((r) => r);
      }

      if (!response || (isArray(response) && !response.length)) return;

      handleResponse(response);
    } catch (error) {
      handleError(error, params, data, queryId);
    } finally {
      if (lastRequestId === requestId) {
        loading.value = false;
      }
    }
  };

  return {
    loading: readonly(loading),
    trigger,
  };
}

export default useLoadingRequest;
