<template>
  <TableBody>
    <TableRow with-border>
      <TableCell class="tw-w-50">
        <FormField v-model="deviceType" type="select" :disabled="locked || disabled" size="small">
          <option v-for="deviceType in recordersConfig.device_types" :key="deviceType" :value="deviceType">
            {{ deviceType }}
          </option>
        </FormField>
      </TableCell>

      <TableCell>
        <FormField v-mask="ipAddressMask" v-model="ipAddress" :disabled="locked || disabled" size="small" />
      </TableCell>

      <TableCell>
        <FormField v-model="name" :disabled="locked || disabled" size="small" />
      </TableCell>

      <TableCell>
        <CircularButton
          v-if="recorder.removable"
          :disabled="locked || disabled"
          icon="trash"
          color="transparentWhite"
          size="small"
          :aria-label="$t('recorders_modal.delete')"
          @click="markDeleted"
        />
      </TableCell>
    </TableRow>
  </TableBody>
</template>

<script setup>
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import { CircularButton, FormField, TableBody, TableCell, TableRow } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { computed, readonly, ref } from 'vue';

const props = defineProps({
  recorder: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['delete']);

const appStore = useAppStore();
const { locked } = storeToRefs(appStore);
const dataStore = useDataStore();
const { recordersConfig } = storeToRefs(dataStore);

const deviceType = ref(props.recorder.device_type);
const ipAddress = ref(props.recorder.ip_address);
const name = ref(props.recorder.recorder_name);
const deleted = ref(false);

const ipAddressMask = computed(() => ({
  mask: '#00.#00.#00.#00:00000',
  tokens: {
    0: { pattern: /[0-9]/, optional: true },
  },
}));

const markDeleted = () => {
  if (locked.value) return;

  deleted.value = true;
  emit('delete', props.recorder.recorder_id);
};

const isValid = () => !!ipAddress.value && !!name.value;

const getData = () => ({
  id: props.recorder.recorder_id,
  changed:
    props.recorder.device_type !== deviceType.value ||
    props.recorder.ip_address !== ipAddress.value ||
    props.recorder.recorder_name !== name.value,
  device_type: deviceType.value,
  ip_address: ipAddress.value,
  recorder_name: name.value,
});

const reset = () => {
  deviceType.value = props.recorder.device_type;
  ipAddress.value = props.recorder.ip_address;
  name.value = props.recorder.recorder_name;
  deleted.value = false;
};

const api = {
  deleted: readonly(deleted),
  isValid,
  getData,
  reset,
};

defineExpose(api);
</script>
