<template>
  <Teleport to="#teleport-container">
    <Modal ref="modal" v-model="show" @opening="reset" @close="reset">
      <ModalContent size="large">
        <template #header>
          <Heading level="h2" level-class="h3">{{ $t('presets_modal.title') }}</Heading>
        </template>

        <template #footer="{ reject }">
          <Button color="transparent" outlined @click="reject">{{ $t('cancel') }}</Button>
          <Button :disabled="locked" type="submit" form="presets-form">{{ $t('save') }}</Button>
        </template>

        <Form id="presets-form" class="tw-grid tw-grid-cols-1 tw-gap-5 sm:tw-grid-cols-2" @submit="save">
          <PresetEditItem v-for="(preset, index) in presets" :key="preset.preset_id" ref="items" :preset :index />
        </Form>
      </ModalContent>
    </Modal>
  </Teleport>
</template>

<script setup>
import api from '@/api';
import useDraftState from '@/composables/draft_state';
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import { Button, Form, Heading, Modal, ModalContent } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { computed, useTemplateRef } from 'vue';

import PresetEditItem from './PresetEditItem.vue';

const appStore = useAppStore();
const { locked } = storeToRefs(appStore);
const dataStore = useDataStore();
const { presets } = storeToRefs(dataStore);

const show = defineModel();
const modalRef = useTemplateRef('modal');
const itemRefs = useTemplateRef('items');

const updatedPresets = computed(() => itemRefs.value.map((itemRef) => itemRef.getData()));

const { trigger: patchPresets } = useDraftState(api.patchPresets, {
  statePath: ['presets'],
  draftStateValue: updatedPresets,
});

const save = async () => {
  const somethingChanged = updatedPresets.value.some((preset) => {
    const originalPreset = presets.value.find(({ preset_id: presetId }) => presetId === preset.preset_id);

    return !originalPreset || originalPreset.name !== preset.name;
  });

  if (somethingChanged) {
    const statePatch = { presets: updatedPresets.value };

    patchPresets(statePatch);
  }

  modalRef.value.accept();
};

const resetNames = () => {
  for (const itemRef of itemRefs.value) {
    itemRef.reset();
  }
};

const reset = () => {
  resetNames();
};
</script>
