import useAppStore from '@/stores/app';
import { useEventListener } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { reactive, readonly, ref, unref } from 'vue';

const usePointermove = (target) => {
  const appStore = useAppStore();
  const { viewZoom } = storeToRefs(appStore);

  const pressed = ref(false);
  const currentPosition = reactive({ x: 0, y: 0 });

  const updateCurrentPositionFromEvent = (event) => {
    const rect = unref(target).getBoundingClientRect();

    Object.assign(currentPosition, {
      x: Math.max(0, Math.min(event.clientX - rect.left, rect.width)) / viewZoom.value,
      y: Math.max(0, Math.min(event.clientY - rect.top, rect.height)) / viewZoom.value,
    });
  };

  useEventListener(target, 'pointerdown', (event) => {
    if (event.button !== 0) return;

    event.preventDefault();
    event.stopPropagation();

    updateCurrentPositionFromEvent(event);

    pressed.value = true;
  });

  useEventListener(window, 'pointermove', (event) => {
    if (!pressed.value) return;

    event.preventDefault();
    event.stopPropagation();

    updateCurrentPositionFromEvent(event);
  });

  useEventListener(window, 'pointerup', (event) => {
    if (!pressed.value) return;

    event.preventDefault();
    event.stopPropagation();

    pressed.value = false;
  });

  useEventListener(window, 'pointercancel', (event) => {
    if (!pressed.value) return;

    event.preventDefault();
    event.stopPropagation();

    pressed.value = false;
  });

  const cancel = () => {
    pressed.value = false;
  };

  return {
    pressed: readonly(pressed),
    currentPosition: readonly(currentPosition),

    cancel,
  };
};

export default usePointermove;
