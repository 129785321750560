<template>
  <ControlSection>
    <div class="tw-flex tw-flex-wrap tw-items-center tw-gap-2">
      <CircularButton
        v-for="move in compositionMoves"
        :key="move.type"
        :ref="move.type"
        :icon="move.icon"
        color="semiTransparentWhite"
        shape="rounded"
        size="large"
        :loading="move.type === 'reset' && resetCompositionLoading"
        :disabled="locked || realignCompositionLoading || resetCompositionLoading"
        :aria-label="move.label"
        class="tw-select-none"
        @click="move.type === 'reset' ? move.action() : undefined"
      />

      <Button
        :loading="realignCompositionLoading"
        :disabled="locked || resetCompositionLoading"
        color="semiTransparentWhite"
        @click="realignComposition"
      >
        {{ $t('control_modes.composition.realign') }}
      </Button>
    </div>

    <Checkbox
      :model-value="composition.sitting_mode"
      :disabled="locked"
      type="switch"
      :right-label="$t('control_modes.composition.sitting_mode')"
      @update:model-value="setSittingMode({ composition: { sitting_mode: $event } })"
    />
  </ControlSection>
</template>

<script setup>
import api from '@/api';
import useDraftState from '@/composables/draft_state';
import useLoadingRequest from '@/composables/loading_request';
import { COMPOSITION_MOVE_STEP } from '@/constants';
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import { Button, Checkbox, CircularButton, useHoldButton } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { useTemplateRef } from 'vue';
import { useI18n } from 'vue-i18n';

import ControlSection from './ControlSection.vue';

const { t } = useI18n();
const appStore = useAppStore();
const { locked } = storeToRefs(appStore);
const dataStore = useDataStore();
const { composition } = storeToRefs(dataStore);

const stepUpRef = useTemplateRef('stepUp');
const stepDownRef = useTemplateRef('stepDown');
const stepLeftRef = useTemplateRef('stepLeft');
const stepRightRef = useTemplateRef('stepRight');

const { trigger: moveCompositionLeft } = useLoadingRequest(api.moveCompositionLeft, { doNotAbortWhenLoading: false });
const { trigger: moveCompositionRight } = useLoadingRequest(api.moveCompositionRight, { doNotAbortWhenLoading: false });
const { trigger: moveCompositionDown } = useLoadingRequest(api.moveCompositionDown, { doNotAbortWhenLoading: false });
const { trigger: moveCompositionUp } = useLoadingRequest(api.moveCompositionUp, { doNotAbortWhenLoading: false });
const { loading: realignCompositionLoading, trigger: realignComposition } = useLoadingRequest(api.realignComposition, {
  withoutData: true,
});
const { loading: resetCompositionLoading, trigger: resetComposition } = useLoadingRequest(api.resetComposition);
const { trigger: setSittingMode } = useDraftState(api.patchState, {
  statePathToClear: ['composition', 'sitting_mode'],
});

useHoldButton(stepUpRef, () => moveCompositionUp({ step: COMPOSITION_MOVE_STEP }));
useHoldButton(stepDownRef, () => moveCompositionDown({ step: COMPOSITION_MOVE_STEP }));
useHoldButton(stepLeftRef, () => moveCompositionLeft({ step: COMPOSITION_MOVE_STEP }));
useHoldButton(stepRightRef, () => moveCompositionRight({ step: COMPOSITION_MOVE_STEP }));

const compositionMoves = [
  {
    label: t('control_modes.composition.up'),
    icon: 'triangle-up',
    type: 'stepUp',
  },
  {
    label: t('control_modes.composition.down'),
    icon: 'triangle-down',
    type: 'stepDown',
  },
  {
    label: t('control_modes.composition.center'),
    icon: 'dot',
    type: 'reset',
    action: resetComposition,
  },
  {
    label: t('control_modes.composition.left'),
    icon: 'triangle-left',
    type: 'stepLeft',
  },
  {
    label: t('control_modes.composition.right'),
    icon: 'triangle-right',
    type: 'stepRight',
  },
];
</script>
