import { DEV_NATE_HOST } from '@/constants';
import { isVariableDefinedNotNull, urlParam } from '@slideslive/fuse-kit/utils';

function buildNateUrl(path = '') {
  if (!isVariableDefinedNotNull(urlParam('nate_host')) && !import.meta.env.PROD && urlParam('use_real_data') === 'true') {
    return `//${DEV_NATE_HOST}${path}`;
  }

  let baseUrl;

  if (isVariableDefinedNotNull(urlParam('nate_host'))) {
    baseUrl = urlParam('nate_host') ? `//${urlParam('nate_host')}` : '';
  } else {
    baseUrl = `//${window.location.hostname}:5003`;
  }

  return `${baseUrl}${path}`
}

export default buildNateUrl;
