import { en as fuseEn } from '@slideslive/fuse-kit/i18n';
import { registerDirectives } from '@slideslive/fuse-kit/vue';
import { vMaska } from 'maska/vue';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';

import App from './App.vue';
import './assets/main.css';
import en from './locales/en.json';

const app = createApp(App);

const i18n = createI18n({
  legacy: false,
  locale: 'en',
  availableLocales: ['en'],
  messages: {
    en: {
      ...fuseEn.en,
      ...en,
    },
  },
});

app.use(createPinia());
app.use(i18n);

registerDirectives(app);

app.directive('mask', vMaska);

app.mount('#app');
