import { DEFAULT_POLLING_INTERVAL, DEFAULT_REFETCH_ON_WINDOW_FOCUS, DEFAULT_RETRY_COUNT } from '@/constants';
import { useEventListener } from '@vueuse/core';
import { onUnmounted, ref, unref, watch } from 'vue';

/**
 * Polling options
 * @typedef {Object} PollingOptions
 * @param {Function} queryFn - Function that is triggered to make the request.
 * @param {boolean} [n=false] enabled - If polling enabled.
 * @param {number} [n=DEFAULT_POLLING_INTERVAL] pollingInterval - Miliseconds between each polling.
 * @param {number} [n=DEFAULT_REFETCH_ON_WINDOW_FOCUS] refetchOnWindowFocus - If should refetch immediately when window
 * focused.
 * @param {number} [n=DEFAULT_RETRY_COUNT] retryCount - Tries before error thrown when not successfull.
 * @return {PollingReturn} - Reactive state and data.
 */

/**
 * Polling return
 * @typedef {Object} PollingReturn
 * @property {boolean} isPending - Reactive state, true until first polling finished.
 * @property {boolean} isLoading - Reactive state, true during first polling.
 * @property {boolean} isFetching - Reactive state, true when polling.
 * @property {boolean} isError - Reactive state, when error occurs.
 * @property {string} error - Error message when error occurs.
 * @property {*} data - Result data of the polling.
 */

/**
 * Composable used for polling remote state
 * Stops when tab blurred, starts again when focused (unless disabled by param).
 *
 * @param {PollingOptions} options - All options the composable takes.
 */
function usePolling({
  enabled = ref(true),
  pollingInterval = ref(DEFAULT_POLLING_INTERVAL),
  refetchOnWindowFocus = ref(DEFAULT_REFETCH_ON_WINDOW_FOCUS),
  retryCount = ref(DEFAULT_RETRY_COUNT),

  queryFn,
}) {
  const isPending = ref(true);
  const isLoading = ref(false);
  const isFetching = ref(false);
  const isError = ref(false);
  const error = ref(null);
  const data = ref(null);

  let timeoutId = null;
  let abortController = null;
  let currentRetries = 0;

  const clearPolling = () => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }

    if (abortController) {
      abortController.abort();
      abortController = null;
    }
  };

  const executePoll = async () => {
    timeoutId = setTimeout(executePoll, unref(pollingInterval));

    if (isFetching.value || !unref(enabled)) return;

    try {
      abortController = new AbortController();
      isFetching.value = true;

      if (data.value === null) {
        isLoading.value = true;
      }

      const newData = await queryFn(null, { abortController });

      if (!newData) throw new Error('Failed to fetch data');

      data.value = newData;
      error.value = null;
      isError.value = false;
      currentRetries = 0;
    } catch (err) {
      if (err.name === 'AbortError') return;

      error.value = err;
      isError.value = true;

      if (currentRetries < unref(retryCount)) {
        currentRetries++;

        return;
      }

      clearTimeout(timeoutId);
    } finally {
      isPending.value = false;
      isFetching.value = false;
      isLoading.value = false;
    }
  };

  watch(
    enabled,
    (newValue) => {
      clearPolling();

      if (newValue) {
        executePoll();
      }
    },
    { immediate: true },
  );

  useEventListener(document, 'visibilitychange', () => {
    if (!unref(refetchOnWindowFocus) || !unref(enabled)) return;

    if (document.visibilityState === 'hidden') {
      clearPolling();
    } else if (document.visibilityState === 'visible') {
      executePoll();
    }
  });

  onUnmounted(() => {
    clearPolling();
  });

  return {
    isPending,
    isLoading,
    isFetching,
    isError,
    error,
    data,
  };
}

export default usePolling;
