<template>
  <nav v-bind="$attrs" class="tw-text-lg">
    <ul :class="['tw-flex', dropdown ? 'tw-flex-col' : 'tw-gap-2']">
      <li
        v-for="item in navItems"
        :key="item.id"
        class="tw-flex after:tw-ml-2 after:tw-w-0.5 after:tw-self-stretch after:tw-bg-purple/25 after:tw-content-none lg:after:tw-content-empty"
      >
        <Item :item :dropdown />
      </li>

      <li
        v-for="(item, index) in otherItems"
        :key="item.id"
        :class="
          resolveTwClasses([
            'lg:tw-hidden xl:tw-flex',
            'after:tw-ml-2 after:tw-w-0.5 after:tw-self-stretch after:tw-bg-purple/25 after:tw-content-none lg:after:tw-content-empty',
            index === otherItems.length - 1 && 'lg:after:tw-content-none',
          ])
        "
      >
        <Item :item :dropdown />
      </li>

      <li class="tw-hidden lg:tw-block xl:tw-hidden">
        <Dropdown :overlay="false" :trigger-attrs="{ linkButton: true, reset: true, scheme: 'underline' }">
          <template #trigger>{{ $t('header_navigation.other') }}</template>

          <template #menu>
            <template v-for="item in otherItems" :key="item.id">
              <Button
                v-if="item.href"
                tag="a"
                :href="item.href"
                target="_blank"
                color="transparentWhite"
                size="small"
                class="tw-text-left"
                block
              >
                {{ item.label }}
              </Button>

              <Button
                v-else-if="item.onClick"
                color="transparentWhite"
                size="small"
                class="tw-w-full tw-text-left"
                block
                @click="item.onClick"
              >
                {{ item.label }}
              </Button>
            </template>
          </template>
        </Dropdown>
      </li>
    </ul>
  </nav>
</template>

<script setup>
import useDataStore from '@/stores/data';
import { resolveTwClasses } from '@slideslive/fuse-kit/utils';
import { Button, Dropdown } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import Item from './Item.vue';

const emit = defineEmits(['openChangelog']);

defineProps({
  dropdown: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();
const dataStore = useDataStore();
const { app } = storeToRefs(dataStore);

const navItems = computed(() => {
  const items = [];

  if (app?.value?.main_cam_url) {
    items.push({
      id: 'main-cam',
      label: t('header_navigation.main_cam'),
      href: app.value.main_cam_url,
    });
  }

  if (app?.value?.wide_cam_url) {
    items.push({
      id: 'wide-cam',
      label: t('header_navigation.wide_cam'),
      href: app.value.wide_cam_url,
    });
  }

  items.push({
    id: 'control-room',
    label: t('header_navigation.control_room'),
    items: [
      {
        id: 'control-room-eu-server',
        label: t('header_navigation.control_room_eu_server'),
        href: 'http://control-room.eu.nate.slideslive.com/',
      },
      {
        id: 'control-room-us-server',
        label: t('header_navigation.control_room_us_server'),
        href: 'http://control-room.us.nate.slideslive.com/',
      },
    ],
  });

  if (app?.value?.nate_runner_link) {
    items.push({
      id: 'nate-runner',
      label: t('header_navigation.nate_runner'),
      href: app.value.nate_runner_link,
    });
  }

  return items;
});

const otherItems = computed(() => {
  const items = [];

  items.push({
    id: 'about',
    label: t('header_navigation.about'),
    href: 'https://slideslive.notion.site/About-RoboCam-and-NATE-2b249515bc3e480a9c549d05d4a09f26',
  });

  items.push({
    id: 'changelog',
    label: app?.value?.nate_version || t('header_navigation.unknown_version'),
    onClick: () => emit('openChangelog'),
  });

  return items;
});
</script>
