<template>
  <CircularButton
    ref="root"
    :icon="icon"
    color="transparentWhite"
    size="small"
    shape="rounded"
    :disabled="disabled"
    :aria-label="label"
    class="tw-select-none"
  />
</template>

<script setup>
import api from '@/api';
import useLoadingRequest from '@/composables/loading_request';
import { TRACKING_ZONE_STEP } from '@/constants';
import { CircularButton, useHoldButton } from '@slideslive/fuse-kit/vue';
import { useTemplateRef, computed } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  edge: {
    type: String,
    required: true,
  },
  action: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();
const rootRef = useTemplateRef('root');

const label = computed(() => t(`control_modes.tracking_zones.move_${props.action}`));
const icon = computed(() => `arrow-${  props.action}`);

const { trigger: moveTrackingZoneEdge } = useLoadingRequest(api.moveTrackingZoneEdge);

useHoldButton(rootRef, () =>
  moveTrackingZoneEdge({
    edge: props.edge,
    action: props.action,
    step: TRACKING_ZONE_STEP
  }, { key: 'zone_id', zoneId: 0 }),
);
</script>
