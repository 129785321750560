<template>
  <ControlSection>
    <FormTag
      v-model="model"
      :disabled="locked"
      type="radio"
      name="controlAction"
      value="move_pan_tilt"
      :label="$t('control_modes.move.pan_tilt')"
      class="tw-whitespace-nowrap"
    />

    <div class="tw-flex tw-flex-wrap tw-items-center tw-gap-2">
      <CircularButton
        v-for="action in cameraActions"
        :key="action.type"
        :ref="action.type"
        :icon="action.icon"
        color="semiTransparentWhite"
        shape="rounded"
        size="large"
        class="tw-select-none"
        :disabled="locked"
        :aria-label="action.label"
      />
    </div>
  </ControlSection>
</template>

<script setup>
import api from '@/api';
import useLoadingRequest from '@/composables/loading_request';
import { CAMERA_MOVE_STEP, CAMERA_ZOOM_STEP } from '@/constants';
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import { CircularButton, FormTag, useHoldButton } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { useTemplateRef } from 'vue';
import { useI18n } from 'vue-i18n';

import ControlSection from './ControlSection.vue';

const { t } = useI18n();
const appStore = useAppStore();
const { locked } = storeToRefs(appStore);
const dataStore = useDataStore();
const { cameras } = storeToRefs(dataStore);

const cameraActions = [
  {
    type: 'tiltUp',
    label: t('control_modes.move.up'),
    icon: 'arrow-up',
  },
  {
    type: 'tiltDown',
    label: t('control_modes.move.down'),
    icon: 'arrow-down',
  },
  {
    type: 'panLeft',
    label: t('control_modes.move.left'),
    icon: 'arrow-left',
  },
  {
    type: 'panRight',
    label: t('control_modes.move.right'),
    icon: 'arrow-right',
  },
  {
    type: 'zoomIn',
    label: t('control_modes.move.zoom_in'),
    icon: 'zoom-in',
  },
  {
    type: 'zoomOut',
    label: t('control_modes.move.zoom_out'),
    icon: 'zoom-out',
  },
];

const model = defineModel();
const tiltUpRef = useTemplateRef('tiltUp');
const tiltDownRef = useTemplateRef('tiltDown');
const panLeftRef = useTemplateRef('panLeft');
const panRightRef = useTemplateRef('panRight');
const zoomInRef = useTemplateRef('zoomIn');
const zoomOutRef = useTemplateRef('zoomOut');

const { trigger: moveCameraInSteps } = useLoadingRequest(api.moveCameraInSteps, { doNotAbortWhenLoading: true });

useHoldButton(
  tiltUpRef,
  () => {
    if (cameras.value.main.tilt.value === cameras.value.main.tilt.max) return;

    moveCameraInSteps({ tilt: { direction: 'up', step: CAMERA_MOVE_STEP } });
  },
  {
    minimumInterval: 100,
  },
);

useHoldButton(
  tiltDownRef,
  () => {
    if (cameras.value.main.tilt.value === cameras.value.main.tilt.min) return;

    moveCameraInSteps({ tilt: { direction: 'down', step: CAMERA_MOVE_STEP } });
  },
  {
    minimumInterval: 100,
  },
);

useHoldButton(
  panLeftRef,
  () => {
    if (cameras.value.main.pan.value === cameras.value.main.pan.min) return;

    moveCameraInSteps({ pan: { direction: 'left', step: CAMERA_MOVE_STEP } });
  },
  {
    minimumInterval: 100,
  },
);

useHoldButton(
  panRightRef,
  () => {
    if (cameras.value.main.pan.value === cameras.value.main.pan.max) return;

    moveCameraInSteps({ pan: { direction: 'right', step: CAMERA_MOVE_STEP } });
  },
  {
    minimumInterval: 100,
  },
);

useHoldButton(
  zoomInRef,
  () => {
    if (cameras.value.main.zoom.value === cameras.value.main.zoom.max) return;

    moveCameraInSteps({ zoom: { direction: 'in', step: CAMERA_ZOOM_STEP } });
  },
  {
    minimumInterval: 100,
  },
);

useHoldButton(
  zoomOutRef,
  () => {
    if (cameras.value.main.zoom.value === cameras.value.main.zoom.min) return;

    moveCameraInSteps({ zoom: { direction: 'out', step: CAMERA_ZOOM_STEP } });
  },
  {
    minimumInterval: 100,
  },
);
</script>
