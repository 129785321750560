<template>
  <Range
    v-model="model"
    :disabled="disabled"
    :min="min"
    :max="max"
    :step="step"
    :label="label"
    :field-wrapper-attrs="{ class: 'tw-mt-1' }"
  >
    <template #output>
      <div class="tw-flex tw-items-center tw-gap-2">
        <Spinner v-if="loading" size="small" />

        <FormField
          v-model.number="input"
          :disabled="disabled"
          type="number"
          :min="min"
          :max="max"
          :step="step"
          round-to-steps
          size="small"
          class="tw-w-14"
          :body-attrs="{ class: 'tw-min-h-5' }"
          :field-attrs="{ class: 'tw-text-sm tw-text-right tw-px-2' }"
          @keydown.enter="setModelFromInput"
          @blur="setModelFromInput"
        />
      </div>
    </template>
  </Range>
</template>

<script setup>
import { FormField, Range, Spinner } from '@slideslive/fuse-kit/vue';
import { onMounted, ref, watch } from 'vue';

defineProps({
  min: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 100,
  },
  step: {
    type: Number,
    default: 1,
  },
  label: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const input = ref(0);
const model = defineModel({
  required: true,
  set(value) {
    if (+value !== input.value) {
      input.value = +value;
    }

    return value;
  },
});

const setModelFromInput = () => {
  if (input.value === '') return;

  model.value = input.value;
};

onMounted(() => {
  input.value = model.value;
});

watch(model, () => {
  if (model.value !== input.value) {
    input.value = model.value;
  }
});
</script>
