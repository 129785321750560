<template>
  <div class="tw-space-y-5">
    <Heading level="h3" level-class="h4">{{ $t('settings_modal.other.title') }}</Heading>

    <div class="tw-flex tw-flex-wrap tw-items-start tw-gap-5">
      <div class="tw-flex tw-flex-wrap tw-items-start tw-gap-2">
        <Button
          color="semiTransparentWhite"
          tag="a"
          :href="mainCamSettingsHref"
          target="_blank"
          download
          class="tw-text-left"
        >
          <Icon name="download" class="tw-mr-2 tw-size-6 tw-fill-current" />
          {{ $t('settings_modal.other.download_main_cam_settings') }}
        </Button>

        <Button color="semiTransparentWhite" :disabled="locked || disabled" @click="emit('resetCamPlatform')">
          {{ $t('settings_modal.other.pan_tilt_recalibration') }}
        </Button>
      </div>

      <FormField
        ref="mainCanSrtStream"
        :value="app.main_cam_srt_stream_path"
        readonly
        :label="$t('settings_modal.other.srt_stream_from_main_camera.label')"
        :right-tag-attrs="{ class: 'tw-pr-2' }"
        class="tw-w-full sm:tw-w-[calc(100%/2-0.625rem)] md:tw-w-[calc(100%/3-0.625rem)] lg:tw-w-[calc(100%/4-0.625rem)]"
      >
        <template #right-tag>
          <CopyToClipboard :target="mainCanSrtStreamRef" size="small" type="circular" color="transparentWhite" />
        </template>
      </FormField>
    </div>
  </div>
</template>

<script setup>
import useAppStore from '@/stores/app';
import useDataStore from '@/stores/data';
import buildNateUrl from '@/utils/build_nate_url';
import { Button, CopyToClipboard, FormField, Heading, Icon } from '@slideslive/fuse-kit/vue';
import { storeToRefs } from 'pinia';
import { computed, useTemplateRef } from 'vue';

defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['resetCamPlatform']);

const appStore = useAppStore();
const { locked } = storeToRefs(appStore);
const dataStore = useDataStore();
const { app } = storeToRefs(dataStore);

const mainCanSrtStreamRef = useTemplateRef('mainCanSrtStream');

const mainCamSettingsHref = computed(() => buildNateUrl('/files/camera_config'));
</script>
